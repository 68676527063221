

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        square: {
            type: Boolean,
            default: false,
        },
        color: {
            type: Boolean,
            default: false
        },
        light: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        medium: {
            type: Boolean,
            default: false,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        tag: {
            type: String,
            default: 'button',
        },
        to: {
            type: Object | String,
            default: null,
        },
        type: {
            type: String,
            default: 'button',
        },
        shadow: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: 'auto',
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleClick() {
            if (!this.disabled && !this.loading) {
                this.$emit('click')
            }
        },
    },
}
