import {mapGetters} from 'vuex';

let applicationHelper = {

    data(){
        return {
            collateralSubjectNames: {
                exclusive_content_right : 'Исключительное право на контент',
                fee_collection_or_content_management : 'Право на получение вознаграждения за использование или распоряжение '
            },
            minimalSum: 15000
        }
    },

    computed: {
        ...mapGetters('profile', ['role']),
        isFinanceRound(){
            return this.application.finance_round !== undefined && this.application.finance_round;
        },
        isShowInvestDocuments(){
            if(this.role === 'investor' && this.isMyInvestition){
                return true;
            }
        },
        isInvestor(){
            return  this.role === 'investor'
        },
        isShowDocuments(){
            return this.role === 'investor' || this.application.is_my;
        },
        isBorrower(){
            return  this.role === 'borrower'
        },
        isRoundOpened() {
            return this.application.status === 'finance_round';
        },
        isMyInvestition(){
            return this.application.invest && this.application.invest.is_my_invest;
        },
        isShowAddToFavoriteButton(){
            return (!this.application.is_my
                && (this.application.status === 'finance_round'
                    || this.application.status === 'approved')
                    && !this.isBorrower
            );
        },
        eightyPercent() {
            return this.application.finance_round.sum * 0.8;
        },
        isMinimalSum() {
            return this.eightyPercent <= this.minimalSum;
        },
        minimalRequestSum() {
            return this.isMinimalSum ? this.minimalSum.toLocaleString('ru-RU') : this.eightyPercent.toLocaleString('ru-RU');
        },
        currentMinimalPercent() {
            if (this.isMinimalSum) {
                return this.minimalSum / (this.application.finance_round.sum / 100);
            }
            return 25; // Процент ширины был до моих изменений 25, его и оставляю
        },
        startDate() {
            return this.startDateObject.toLocaleDateString('ru-RU')
        },
        startTime() {
            return this.startDateObject.toLocaleString().split(',').pop();
        },
        endDate() {
            return this.endDateObject.toLocaleDateString('ru-RU')
        },
        endTime() {
            return this.endDateObject.toLocaleString().split(',').pop();
        },
        tenPrecentMenbers() {
            let members = [];
            if (this.application.organization !== undefined && this.application.organization.structure_info) {
                for (let structureGroup of this.application.organization.structure_info) {
                    for (let member of structureGroup.members) {
                        if(parseFloat(member.percent) > 10.){
                            members.push(
                                {
                                    'name': `${member.surname ? member.surname : ''} ${member.name} ${member.patronymic ? member.patronymic : ''}`,
                                    'percent': member.percent
                                }
                            );
                        }
                    }
                }
            }

            return members;
        },
        croudPercentage() {
            return Math.floor(this.application.current_sum / (parseFloat(this.application.finance_round ? this.application.finance_round.sum : 0, 10) / 100));
        }
    },
    methods: {
        isAudio(type){
            return type === 'audio'
                || type === 'music_composition_with_text'
                || type === 'music_composition_without_text'
                || type === 'music_phonogram'
                || type === 'music_performance'
        },
        participantNamesByType(authors, type) {
            let authorNames = [];
            let authorName = '';
            if (authors) {
                for (let author of authors) {
                    if ((author.type === type || !type) || (Array.isArray(type)) && type.includes(author.type)) {

                        let fullName = '';

                        if (author.surname) {
                            fullName = author.surname;
                        }
                        if (author.name) {
                            fullName += '.' + author.name.substr(0, 1)
                        }
                        if (author.patronymic) {
                            fullName += '.' + author.patronymic.substr(0, 1)
                        }

                        authorNames.push(fullName);
                    }
                }
                authorName = authorNames.join(', ');
            }

            return `${authorName}`;
        },
        priceFormat(value) {
            return Number(value)
                ? Number(value).toLocaleString('ru-RU', {
                })
                : 0
        },
        loanTermMonth(value){
            let monthText = 'месяцев';
            if(value === 24){
                monthText = 'месяца';
            }

            return `${value} ${monthText}`
        },
        interestRates(interest_rate_from, interest_rate_to){
            if (interest_rate_from === interest_rate_to) {
                return interest_rate_from + '%'
            }

            return `${interest_rate_from} - ${interest_rate_to}%`
        },
        setStartDateObject() {
            if(this.isFinanceRound) {
                this.endDateObject = new Date(this.application.finance_round.end_date);
                this.startDateObject = new Date(this.application.finance_round.end_date);

                switch (this.application.finance_round.period) {
                    case '2 day':
                        this.startDateObject.setDate(this.startDateObject.getDate() - 2)
                        break;
                    case '7 day':
                        this.startDateObject.setDate(this.startDateObject.getDate() - 7)
                        break;
                    case '1 month':
                        this.startDateObject.setMonth(this.startDateObject.getMonth() - 1)
                        break;
                }
            }
        }
    }
}

export default applicationHelper;
