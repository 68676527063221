export default async function (context) {
    // if (process.server && context.$cookies.get('token')) {
    //     await context.store.dispatch('profile/getCredentials').then(res => {
    //         context.store.dispatch('profile/setCredentials', res.data.item)
    //     }).catch(() => {})
    //     await context.store.dispatch('profile/getAuthInfo').then(res => {
    //         context.store.dispatch('profile/setAuthInfo', res.data)
    //         context.store.dispatch('profile/setAuthorized', true)
    //     }).catch(() => {
    //         context.store.dispatch('profile/setCredentials', null)
    //         context.store.dispatch('profile/setAuthorized', false)
    //     })
    // }
}