
import Vue from 'vue'
import BaseHeader from '~/components/base/Header';
import BaseFooter from '~/components/base/Footer';
import Modals from '~/components/base/Modals';
import global from '~/mixins/global';
import { mapState } from 'vuex';
import RefreshTokenProvider from '~/components/RefreshTokenProvider';
import { AUTH_PROFILE_REMODERATE } from '~/const/api/auth';

Vue.mixin(global);

export default {
    name: 'LayoutDefault',
    components: {
        BaseHeader,
        BaseFooter,
        RefreshTokenProvider,
        Modals
    },
    data() {
        return {
            loading: false
        }
    },
    watch: {
        '$route': {
            handler() {
                setTimeout(() => {
                    if (!this.isProfilePage) {
                        if (this.isRejected) {
                            return this.showModalReason();
                        } else if (!this.isHomePage && this.currentAccount && !this.currentAccount.declaration_signed) {
                            return this.showModalDeclaration();
                        }
                    }
                }, 0);
            },
            immediate: true
        }
    },
    computed: {
        isRejected() {
            return !this.currentAccount && this.lastActive && this.lastActive.status === 'rejected'
        },
        isProfilePage() {
            return this.$route.name === 'PersonalProfile'
            || this.$route.name === 'IndividualProfileGeneralInfo'
            || this.$route.name === 'PersonalProfileGeneralInfo'
            || this.$route.name === 'EntityProfileGeneralInfo'
        },
        isHomePage() {
            return this.$route.name === 'index';
        },
        headerTheme() {
            return this.$route.name === 'index' || this.$route.name === 'investor' || this.$route.name === 'borrower' ? 'light' : 'black';
        },
        ...mapState('profile', ['currentAccount', 'profiles', 'lastActive'])
    },
    methods: {
        showModalDeclaration() {
            this.$modal.show('default-modal', {
                title: `Данные подтверждены`,
                maxWidth: 620,
                cross: false,
                clickOutside: false,
                content: {
                    component: () => import('~/components/common/Declaration.vue')
                }
            })
        },
        showModalReason() {
            this.$modal.show('default-modal', {
                title: `Ваш профиль заблокирован`,
                maxWidth: 620,
                cross: false,
                clickOutside: false,
                content: {
                    component: () => import('~/components/common/DialogModalContent.vue'),
                    props: {
                        description: `Ваша учетная запись заблокирована по причине: "${this.lastActive.rejecting_reason}"`,
                        buttons: [
                        // {
                        //     title: 'Отправить повторно',
                        //     class: 'ma-2',
                        //     style: { minWidth: 'auto', width: '240px' },
                        //     handler: this.resendToModeration
                        // },
                        {
                            title: 'Изменить данные',
                            class: 'ma-2',
                            light: true,
                            style: { minWidth: 'auto', width: '240px' },
                            handler: this.goToChangeCredentials
                        }],
                    }
                }
            })
        },
        resendToModeration() {
            if (!this.loading) {
                this.loading = true;
                this.$axios.post(AUTH_PROFILE_REMODERATE(this.lastActive.id))
                    .then(() => {
                        this.$modal.hide('default-modal');
                        this.$router.push({
                            name: 'profile-my-profiles'
                        });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        goToChangeCredentials() {
            this.$modal.hide('default-modal')
            switch(this.lastActive.type) {
                case 'individual':
                    this.$router.push({
                        name: 'profile-individual-profile-id',
                        params: {
                            id: this.lastActive.id
                        }
                    })
                    break;
                case 'entity':
                    this.$router.push({
                        name: 'profile-entity-profile-id',
                        params: {
                            id: this.lastActive.id
                        }
                    })
                    break;
                case 'personal':
                    this.$router.push({
                        name: 'profile-personal-profile-id',
                        params: {
                            id: this.lastActive.id
                        }
                    });
                    break;
            }
        },
    }
}
