
import { mapGetters } from 'vuex'

export default {
    name: 'VCheckbox',
    props: {
        tooltip: {
            type: String,
            default: ''
        },
        error: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        val: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        validate: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    computed: {
        ...mapGetters('profile', [
            'role'
        ]),
        localValue: {
            get() {
                return this.val || this.value
            },
            set(value) {
                if (!this.disabled) this.$emit('input', value)
            },
        },
    },
}
