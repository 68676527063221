
import VLabel from '~/components/ui/VLabel'
import { mixFieldValidate } from '@/mixins/validate'
import _ from 'lodash'

export default {
    name: 'VFormGroup',
    components: {
        VLabel,
        VInput: () => import('~/components/ui/VInput'),
        VTextarea: () => import('~/components/ui/VTextarea'),
        VSelect: () => import('~/components/ui/VSelect'),
        VDatePicker: () => import('~/components/ui/VDatePicker'),
        VInputWithSuggestions: () => import('~/components/ui/VInputWithSuggestions'),
        VBankAccounts: () => import('~/components/ui/VBankAccounts')
    },
    mixins: [mixFieldValidate],
    inheritAttrs: false,
    props: {
        label: {
            type: String,
            default: null
        },
        staticLabel: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'input'
        },
        inputType: {
            type: String,
            default: 'text'
        },
        value: {
            type: [String, Number, Boolean, Object, Array],
            default: ''
        },
        validate: {
            type: Object,
            default: null,
        },
        error: {
            type: [String, Array],
            default: ''
        },
        validateErrorMessages: {
            type: Object
        },
        readonly: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            focused: false
        }
    },
    computed: {
        errorString() {
            if (_.isArray(this.error)) {
                return this.error.join('\r\n')
            }
            return this.error
        },
        isFloat() {
            switch (this.type) {
                case 'select':
                    return this.value !== null;
                default:
                    return !!this.value;
            }
        },
        isErrorValidate() {
            return this.validate && this.validate.$error && this.validate.$dirty;
        }
    },
    methods: {
        handleFocus() {
            this.focused = true;
            this.$emit('focus');
        },
        handleBlur() {
            this.focused = false;
            this.$emit('blur');
        }
    }
}
