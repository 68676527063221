
import { mapState, mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

export default {
    name: 'BaseProfileMenu',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            mainMenuOpen: false,
            subMenuOpen: false
        }
    },
    watch: {
        $route() {
            this.handleOutside()
        }
    },
    computed: {
        ...mapState('profile', [
            'currentAccount',
            'profiles'
        ]),
        ...mapGetters('profile', ['role']),
        sortedProfiles() {
            if (this.profiles) {
                return _.cloneDeep(this.profiles).sort((a, b) => a.is_active === b.is_active ? 0 : a.is_active ? 1 : -1);
            }
            return []
        }
    },
    methods: {
        ...mapActions('profile', ['changeCurrentProfile']),
        toggleSubMenu() {
            this.subMenuOpen = !this.subMenuOpen
        },
        changeProfile(profile) {
            if (!profile.is_active && (profile.status === 'verified' || profile.status === 'identified')) {
                this.changeCurrentProfile(profile.account_id)
            }
        },
        emptyProfileName(profile) {
            switch (profile.type) {
                case 'entity':
                    return 'Профиль ЮЛ'
                case 'personal':
                    return 'Профиль ФЛ'
                default:
                    return 'Профиль ИП'
            }
        },
        handleClick() {
            if (this.subMenuOpen) {
                this.subMenuOpen = false;
            }
            this.mainMenuOpen = !this.mainMenuOpen;
        },
        handleClickByItem(item) {
            if (item.id === 'changeProfile') {
                this.subMenuOpen = !this.subMenuOpen;
            } else if (item.fn) {
                item.fn();
                this.handleOutside();
            }
        },
        handleReturn() {
            this.subMenuOpen = false;
            this.handleClick();
        },
        handleOutside() {
            this.mainMenuOpen = false;
            this.subMenuOpen = false;
        }
    }
}
