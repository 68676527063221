import { FAVORITE_ADD, FAVORITE_REMOVE, FAVORITE_MY, FAVORITE_COUNT} from '~/const/api/favorite';

const state = () => ({
    loans: [],
    count: 0,
});

const actions = {
    setToFavourite({ dispatch }, id) {
        return this.$axios.post(FAVORITE_ADD, {
            id: id
        })
            .then(({ data }) => {
                dispatch('getFavouritesCount');
                return data;
            });
    },
    removeFromFavourite({ dispatch }, id) {
        return this.$axios.post(FAVORITE_REMOVE, {
            id: id
        })
            .then(({ data }) => {
                dispatch('getFavouritesCount');
                return data;
            });
    },
    async loadFavouritiesLoans({ commit, rootGetters}, params) {
        if (rootGetters['profile/role'] && rootGetters['profile/role'] === 'investor') {
            const res = await this.$axios(FAVORITE_MY, {
                params: params
            })
            if (res) {
                commit('SET_FAVORITES', res.data.items);
            }
            return res
        }
    },
    getFavouritesCount({ commit, rootGetters }) {
        this.$axios(FAVORITE_COUNT)
            .then(({ data }) => {
                commit('SET_FAVORITES_COUNT', data.item.count);
            });
    }
}


const getters = {
    getFavorites: async () => {
        let loans = state.loans;
        return loans;
    }
}

const mutations = {
    SET_FAVORITES(state, items) {
        state.loans = items;
    },
    SET_FAVORITES_COUNT(state, count) {
        state.count = count
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
