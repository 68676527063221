
import VButton from '~/components/ui/VButton'
import ResendSms from '~/components/common/ResendSms'
import {mapActions, mapGetters, mapState} from 'vuex'


export default {
    name: 'OpenRound',
    components: {
        VButton,
        ResendSms,
    },
    props: {
        pdfOffer: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            alert: '',
            sms: '',
            applicationId: null,
            fileOffertUrl: '',
            callback: null,
            loading: false,
        }
    },
    computed: {
        ...mapState('profile', [
            'currentAccount'
        ]),
        phone() {
            return this.currentAccount ? this.currentAccount.phone_number : ''
        },
        disabled() {
            return (this.sms.length < 4)
        },
    },

    methods: {
        ...mapActions('service', [
            'confirmInvest',
            'invest'
        ]),
        ...mapGetters('service', [
            'reInvest'
        ]),
        ...mapActions('profile', [
            'getAuthInfo',
            'setAuthInfo'
        ]),
        beforeClose() {
            this.$axios.get('invest/cash_hold_remove');
            this.alert = ''
            this.sms = ''
            this.applicationId = null
            this.fileOffertUrl = ''
            this.callback = null
        },
        beforeOpen(event) {
            this.application = event.params.application;
            this.applicationId = event.params.application.id;
            //this.applicationId = this.$route.query.card
            this.fileOffertUrl = this.$config.PUBLIC_API + '/file/inline/';
            this.callback = event.params.callback || null
        },
        async enterCode() { // запрос на ввод смс кода
            if (!this.loading) {
                this.loading = true;
                this.alert = '';
                const data = {
                    application_id: this.applicationId,
                    code: parseInt(this.sms, 10)
                };
                this.confirmInvest(data)
                    .then(() => {
                        if (this.callback) this.callback();
                        this.getAuthInfo().then(({ data }) => this.setAuthInfo(data));
                        this.$modal.hide('project-invest-confirm');
                    })
                    .catch(err => {
                        if (err.response.data?.payload?.validation_error) {
                            if (err.response.data.payload.validation_error?.target_user_ip_chain_id) {
                                this.alert = err.response.data.payload.validation_error.target_user_ip_chain_id[0];
                            }
                        } else if (err.response.data.errors && err.response.data.errors.code !== undefined) {
                            this.alert = err.response.data.errors.code;
                        } else if (err.response.data.errors && err.response.data.errors.invest) {
                            this.alert = err.response.data.errors.invest;
                        } else if (err.response.data.error_message !== undefined && err.response.data.error_message) {
                            this.alert = err.response.data.error_message;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        async resendRequest() { // повторная отправка запроса на получение смс кода
            this.loading = true;
            this.sms = '';
            this.alert = '';
            try {
                await this.invest(this.reInvest);
                this.loading = false
            } catch (e) {
                this.loading = false
            }
        },
        ...mapActions('profile', ['getAuthInfo', 'setAuthInfo'])
    }
}
