
export default {
  name: 'AppResendSms',
  props: {
    total: Number,
    color: String,
    blue: Boolean
  },
  data () {
    return {
      resendReady: false,
			totalTime: this.total != undefined ? parseInt(this.total, 10) : 54,
      declension: '',
      seconds: null,
    }
  },
  watch: {
    seconds () {
      this.seconds % 10 == 1 && this.seconds != 11 ? this.declension = 'у' : (this.seconds % 10 == 4 || this.seconds % 10 == 3 || this.seconds % 10 == 2) && (this.seconds != 14 && this.seconds != 13 && this.seconds != 12 && this.seconds != 11) ? this.declension = 'ы' : this.declension = ' '
    }
  },
  methods: {
    clicked (e) {
		  this.timer();
      e.preventDefault();
      if (this.disabled) return;
      return this.$emit('click', e)
    },
		timer() {
			this.resendReady = false;
			this.seconds = this.totalTime;
			var timer = setInterval(() => {
        this.seconds--;
        this.declinations();
				if (this.seconds < 0) {
					clearInterval(timer);
					return (this.resendReady = true)
				}
			}, 999)
    },
    declinations() {
      let seconds = this.seconds
    },
  },
  mounted () {
		this.seconds = this.totalTime;
		this.timer()
  }
}
