
import {mapActions, mapState} from "vuex";

export default {
    name: 'RefreshTokenProvider',
    watch: {
        isRefreshToken: {
            handler(value) {
                if (value) {
                    this.$nuxt.setLayout('loading');
                    this.refreshToken()
                        .then(() => {
                            this.$nuxt.setLayout('default');
                        })
                        .catch(() => {
                            return this.$router.push({ name: 'index' });
                        })
                        .finally(() => {
                            this.setIsRefreshToken(false);
                        })
                }
            },
            immediate: true
        }
    },
    computed: {
        ...mapState('profile', [
            'isAuthorized',
            'isRefreshToken'
        ])
    },
    methods: {
        ...mapActions('profile', ['refreshToken', 'setIsRefreshToken'])
    },
}
