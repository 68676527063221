
import VButton from '~/components/ui/VButton'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'RoundAlert',
    components: {
        VButton
    },
    computed: {
        ...mapState('profile', [
            'isAuthorized',
            'profiles',
        ]),
        ...mapGetters('profile', [
            'role'
        ]),
        isIndividual() {
            if (this.currentAccount) {
                return this.currentAccount.type === 'individual'
            } else {
                return this.profiles[0].type === 'individual'
            }
        },
        signedDeclaration() {
            if (this.currentAccount) {
                return this.currentAccount.status === 'identified' && this.currentAccount.declaration_signed
            } else {
                return this.profiles[0].status === 'identified' && this.profiles[0].declaration_signed
            }
        },
    },
    methods: {
        beforeOpen(event) {
            
        },
        beforeClose(data) {
            
        },
        closeModal() {
            this.$modal.hide('modal-special-fund')
        },
        signUp() {
            window.open('/register?page=fund', '_self');
        },
    },
}
