import { AUTH_LOGOUT, AUTH_REFRESH_TOKEN, AUTH_PROFILE_ACTIVATE, AUTH_INFO } from '~/const/api/auth';

const seconds = 60000

const AUTH = 'auth'
const USER = 'user'
const EXPIRES_TIME = '_exp'

const state = () => {
    return {
        credentials: null,
        currentAccount: null,
        isAuthorized: false,
        signToken: null,
        isRefreshToken: false,
        lastActive: null,
        profiles: [],
        role: null,
        bankAccounts: []
    }
}

const getters = {
    role: (state) => state.credentials ? state.credentials.roles[0] : state.role,
}

const actions = {
    setSignToken({ commit }, value) {
        commit('SET_SIGN_TOKEN', value);
    },
    async createToken() {
        const res = this.$axios.post('/register/sign-token')
        return res 
    },
    async getCredentials() {
        const res = await this.$axios('/auth/credentials')
        return res
    },
    changeCurrentProfile({ dispatch }, id) {
        return this.$axios.post(AUTH_PROFILE_ACTIVATE(id))
            // .then(() => {
            //     return dispatch('getCurrentAccount');
            // })
            .then(data => {
                if (window) {
                    window.location.reload()
                }
            })
    },
    getCurrentAccount() {
        return this.$axios('/auth/profile')
            .then(({ data }) => {
                return data.data;
            });
    },
    async getAuthInfo() {
        const res = await this.$axios(AUTH_INFO)
        return res
    },
    setAuthInfo({ commit }, data) {
        commit('SET_CURRENT_ACCOUNT', data.user_data.profiles.find(el => el.id === data.user_data.active_profile))
        commit('SET_PROFILES', data.user_data.profiles)
        commit('SET_LAST_ACTIVE', data.user_data.last_active)
        commit('SET_ROLE', data.user_data.role)
        commit('SET_AUTHORIZED', true)
    },
    setCredentials({ commit }, credentials) {
        commit('SET_CREDENTIALS', credentials);
    },
    setAuthorized({ commit }, payload) {
        commit('SET_AUTHORIZED', payload);
    },
    logout({ commit }) {
        this.$axios.post(AUTH_LOGOUT);
        localStorage.removeItem(AUTH)
        localStorage.removeItem(EXPIRES_TIME)
        commit('SET_CURRENT_ACCOUNT', null);
        commit('SET_LAST_ACTIVE', null);
        commit('SET_CREDENTIALS', null);
        commit('SET_AUTHORIZED', false);
        commit('SET_PROFILES', []);
    },
    async refreshToken() {
        const res = await this.$axios(AUTH_REFRESH_TOKEN);
        return res
    },
    setIsRefreshToken({ commit }, payload) {
        commit('SET_IS_REFRESH_TOKEN', payload);
    },
    setSignToken({ commit }, payload) {
        commit('SET_SIGN_TOKEN', payload);
    },
    setBankAccounts({ commit }, payload) {
        commit('SET_BANK_ACCOUNTS', payload);
    },
    addBankAccounts({ commit }, payload) {
        commit('ADD_BANK_ACCOUNTS', payload);
    }
}

const mutations = {
    SET_BANK_ACCOUNTS(state, payload) {
        if (state?.currentAccount) {
            state.currentAccount.bankAccounts = payload;
        }
    },
    ADD_BANK_ACCOUNTS(state, payload) {
        if (state?.currentAccount?.bankAccounts) {
            state.currentAccount.bankAccounts.push(...payload);
        }
    },
    SET_SIGN_TOKEN(state, value) {
        state.signToken = value
    },
    SET_ROLE(state, role) {
        state.role = role
    },
    SET_PROFILES(state, profiles) {
        state.profiles = profiles
    },
    SET_CURRENT_ACCOUNT(state, account) {
        state.currentAccount = account;
    },
    SET_LAST_ACTIVE(state, value) {
        state.lastActive = value
    },
    SET_CREDENTIALS(state, credentials) {
        state.credentials = credentials;
    },
    SET_AUTHORIZED(state, payload) {
        state.isAuthorized = payload;
    },
    SET_IS_REFRESH_TOKEN(state, payload) {
        state.isRefreshToken = payload;
    },
    SET_SIGN_TOKEN(state, payload) {
        state.signToken = payload;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
