const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['investor-access'] = require('../middleware/investor-access.js')
middleware['investor-access'] = middleware['investor-access'].default || middleware['investor-access']

middleware['profile'] = require('../middleware/profile.js')
middleware['profile'] = middleware['profile'].default || middleware['profile']

middleware['redirect-to-slug'] = require('../middleware/redirect-to-slug.js')
middleware['redirect-to-slug'] = middleware['redirect-to-slug'].default || middleware['redirect-to-slug']

export default middleware
