
import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapState('profile', [
            'currentAccount',
            'isAuthorized',
            'credentials',
            'profiles'
        ]),
        ...mapGetters('profile', [
            'role'
        ]),
    },
    methods: {
        goToSupport() {
            this.$emit('close')
            this.$router.push({
                name: 'profile-support'
            })
        },  
        goToProfilePage() {
            this.$emit('close')
            if (this.currentAccount) {
                switch(this.currentAccount.type) {
                        case 'individual':
                            this.$router.push({
                                name: 'profile-individual-profile-id',
                                params: {
                                    id: this.currentAccount.account_id
                                }
                            })
                            break;
                        case 'entity':
                            this.$router.push({
                                name: 'profile-entity-profile-id',
                                params: {
                                    id: this.currentAccount.account_id
                                }
                            })
                            break;
                        case 'personal':
                            this.$router.push({
                                name: 'profile-personal-profile-id',
                                params: {
                                    id: this.currentAccount.account_id
                                }
                            })
                            break;
                    }
            }
        },
        handleLogout() {
            this.$emit('close')
            this.$router.push({
                name: 'logout'
            })
        },
    }
}
