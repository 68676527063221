
import ResendSmsInvestCancel from '~/components/common/ResendSmsInvestCancel'
import VButton from '~/components/ui/VButton'

import {mapActions, mapGetters, mapState} from 'vuex'

export default {
    name: 'RoundAlert',
    components: {
        VButton,
        ResendSmsInvestCancel
    },
    data() {
        return {
            alert: '',
            sms: '',
            application: null,
            acceptRejectDocumentLink: null,
            loading: false,
        }
    },
    computed: {
        ...mapGetters('service', ['reInvest']),
        ...mapState('profile', [
            'currentAccount'
        ]),
        phone() {
            return this.currentAccount ? this.currentAccount.phone_number.replace(/\(|\)/g, '').replace(/\-|\-/g, ' ') : ''
        },
        disabled() {
            return this.sms.length < 4
        },
    },
    methods: {
        beforeOpen(event) {
            this.application = event.params.application
            this.callback = event.params.callback || null
            this.loadAcceptRejectDocument();
        },
        beforeClose(data) {
            this.sms = '';
            this.callback = null;
            this.alert = ''
        },
        async loadAcceptRejectDocument() {
            await this.$axios.post(`/pre-accept-reject/${this.application.id}`)
                .then((res) => {
                    this.acceptRejectDocumentLink = res.data.item.file;
                })
                .catch((err) => {
                })
        },
        async enterCode() {
            if (!this.loading) {
                this.alert = ''
                this.loading = true;
                // запрос на ввод смс кода
                const data = {
                    id: this.application.id,
                    code: parseInt(this.sms, 10),
                }
                this.cancelInvestConfirm(data)
                    .then(() => {
                        if (this.callback) this.callback();
                        this.getAuthInfo().then(({ data }) => this.setAuthInfo(data));
                        this.$modal.hide('project-invest-cancel-confirm');
                    })
                    .catch(e => {
                        if (e.response.data.errors && e.response.data.errors.code) this.alert = e.response.data.errors.code
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        async resendRequest() {
            // повторная отправка запроса на получение смс кода
            this.loading = true
            this.alert = ''
            try {
                const request = await this.cancelInvest(
                    this.application.id
                )
                this.loading = false
            } catch (e) {
                this.loading = false
            }
        },
        ...mapActions('service', [
            'cancelInvest',
            'cancelInvestConfirm',
        ]),
        ...mapActions('profile', [
            'getAuthInfo',
            'setAuthInfo'
        ]),
    },

}
