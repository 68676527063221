
import { get } from 'lodash';

export default {
    name: 'VDropdown',
    props: {
        value: {
            type: [String, Number, Boolean, Object],
            default: ''
        },
        items: {
            type: Array,
            default: () => []
        },
        fieldTitle: {
            type: String,
            default: null
        },
        fieldValue: {
            type: String,
            default: null
        },
        returnObject: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleClick(item) {
            if (this.returnObject) return this.$emit('select', item);
            return this.$emit('select', this.fieldValue ? get(item, this.fieldValue) : item);
        },
        checkSelected(item) {
            if (this.value) {
                if (this.returnObject && this.fieldTitle && this.fieldValue) {
                    return get(item, this.fieldValue) === get(this.value, this.fieldValue);
                } else if (this.fieldTitle && this.fieldValue) {
                    return get(item, this.fieldValue) === this.value;
                }
                return this.value === item;
            }
            return false;
        }
    }
}
