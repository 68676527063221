export default {
    methods: {
        glCreateCost(value, currency = 'RUB', options = {}, locale = 'ru-RU') {
            const number = Number(value);
            if (isNaN(value)) {
                console.error('glCreateCost работает только с числами', typeof value, value);
            } else {
                return number.toLocaleString(locale, {
                    ...options,
                    ...(() => currency ? {
                        style: 'currency',
                        currency,
                    } : false)()
                })
            }
        },
        noun(number, titles) {
            const cases = [2, 0, 1, 1, 1, 2]
            return titles[
                number % 100 > 4 && number % 100 < 20
                    ? 2
                    : cases[number % 10 < 5 ? number % 10 : 5]
            ]
        },
        serverFileStreamUrl(id) {
            return `${this.$config.PUBLIC_API}/file/stream/${id}`;
        },
        serverFileUrl(id) {
            return `${this.$config.PUBLIC_API}/file/${id}`;
        },
        serverImage(id, route = '') {
            return `${this.$config.PUBLIC_API}${route ? route : `/file/inline/`}${id}`;
        },
    }
}
