import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _368b5adc = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _999675e2 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _12bb0ebd = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _4c92363e = () => interopDefault(import('../pages/documents.vue' /* webpackChunkName: "pages/documents" */))
const _b735053e = () => interopDefault(import('../pages/elements.vue' /* webpackChunkName: "pages/elements" */))
const _41d65b9f = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _66d20882 = () => interopDefault(import('../pages/investor.vue' /* webpackChunkName: "pages/investor" */))
const _49c57cf4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _1ec0dc7b = () => interopDefault(import('../pages/register-email-link.vue' /* webpackChunkName: "pages/register-email-link" */))
const _738b56aa = () => interopDefault(import('../pages/register-type.vue' /* webpackChunkName: "pages/register-type" */))
const _82f1c3e4 = () => interopDefault(import('../pages/registration-second.vue' /* webpackChunkName: "pages/registration-second" */))
const _8cdc4f4e = () => interopDefault(import('../pages/registration-success.vue' /* webpackChunkName: "pages/registration-success" */))
const _80c979a2 = () => interopDefault(import('../pages/sign-up-entity.vue' /* webpackChunkName: "pages/sign-up-entity" */))
const _42e3bf51 = () => interopDefault(import('../pages/vitrina.vue' /* webpackChunkName: "pages/vitrina" */))
const _172d2736 = () => interopDefault(import('../pages/email/verify.vue' /* webpackChunkName: "pages/email/verify" */))
const _991b3308 = () => interopDefault(import('../pages/profile/balance.vue' /* webpackChunkName: "pages/profile/balance" */))
const _44df1fff = () => interopDefault(import('../pages/profile/balance/index.vue' /* webpackChunkName: "pages/profile/balance/index" */))
const _d845669e = () => interopDefault(import('../pages/profile/balance/replenishment.vue' /* webpackChunkName: "pages/profile/balance/replenishment" */))
const _18c01b4e = () => interopDefault(import('../pages/profile/balance/transfer.vue' /* webpackChunkName: "pages/profile/balance/transfer" */))
const _4ff04c68 = () => interopDefault(import('../pages/profile/create-profile.vue' /* webpackChunkName: "pages/profile/create-profile" */))
const _6f30b798 = () => interopDefault(import('../pages/profile/dashboard.vue' /* webpackChunkName: "pages/profile/dashboard" */))
const _51a15a58 = () => interopDefault(import('../pages/profile/documents.vue' /* webpackChunkName: "pages/profile/documents" */))
const _83d55b0c = () => interopDefault(import('../pages/profile/favourites.vue' /* webpackChunkName: "pages/profile/favourites" */))
const _59b70c78 = () => interopDefault(import('../pages/profile/my-offers.vue' /* webpackChunkName: "pages/profile/my-offers" */))
const _7d29e6c6 = () => interopDefault(import('../pages/profile/my-orders.vue' /* webpackChunkName: "pages/profile/my-orders" */))
const _39c3570b = () => interopDefault(import('../pages/profile/my-profiles.vue' /* webpackChunkName: "pages/profile/my-profiles" */))
const _65901467 = () => interopDefault(import('../pages/profile/oises.vue' /* webpackChunkName: "pages/profile/oises" */))
const _3539ea7a = () => interopDefault(import('../pages/profile/order-detail/index.vue' /* webpackChunkName: "pages/profile/order-detail/index" */))
const _9f86f352 = () => interopDefault(import('../pages/profile/order-detail/index/_id/documents.vue' /* webpackChunkName: "pages/profile/order-detail/index/_id/documents" */))
const _4a760e0c = () => interopDefault(import('../pages/profile/order-detail/index/_id/investors.vue' /* webpackChunkName: "pages/profile/order-detail/index/_id/investors" */))
const _306bfbf8 = () => interopDefault(import('../pages/profile/order-detail/index/_id/project.vue' /* webpackChunkName: "pages/profile/order-detail/index/_id/project" */))
const _344e3292 = () => interopDefault(import('../pages/profile/order-detail/index/_id/repayment.vue' /* webpackChunkName: "pages/profile/order-detail/index/_id/repayment" */))
const _260f7070 = () => interopDefault(import('../pages/profile/order-detail/index/_id/schedule.vue' /* webpackChunkName: "pages/profile/order-detail/index/_id/schedule" */))
const _036a459c = () => interopDefault(import('../pages/profile/support/index.vue' /* webpackChunkName: "pages/profile/support/index" */))
const _50d807ab = () => interopDefault(import('../pages/profile/application/create.vue' /* webpackChunkName: "pages/profile/application/create" */))
const _31c65782 = () => interopDefault(import('../pages/profile/ois/create.vue' /* webpackChunkName: "pages/profile/ois/create" */))
const _1a75d868 = () => interopDefault(import('../pages/profile/support/create.vue' /* webpackChunkName: "pages/profile/support/create" */))
const _3a5061e3 = () => interopDefault(import('../pages/profile/support/success.vue' /* webpackChunkName: "pages/profile/support/success" */))
const _71c08a0c = () => interopDefault(import('../pages/profile/entity-profile/_id.vue' /* webpackChunkName: "pages/profile/entity-profile/_id" */))
const _117a91b0 = () => interopDefault(import('../pages/profile/individual-profile/_id.vue' /* webpackChunkName: "pages/profile/individual-profile/_id" */))
const _cc1e67b8 = () => interopDefault(import('../pages/profile/ois/_id.vue' /* webpackChunkName: "pages/profile/ois/_id" */))
const _5120f552 = () => interopDefault(import('../pages/profile/personal-profile/_id.vue' /* webpackChunkName: "pages/profile/personal-profile/_id" */))
const _4353360b = () => interopDefault(import('../pages/profile/support/_ticket.vue' /* webpackChunkName: "pages/profile/support/_ticket" */))
const _4fd6b4ae = () => interopDefault(import('../pages/profile/application/_id/edit.vue' /* webpackChunkName: "pages/profile/application/_id/edit" */))
const _9d03b872 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _401fba57 = () => interopDefault(import('../pages/faq/_slug.vue' /* webpackChunkName: "pages/faq/_slug" */))
const _1a006b9a = () => interopDefault(import('../pages/project/_slug.vue' /* webpackChunkName: "pages/project/_slug" */))
const _7b66aa03 = () => interopDefault(import('../pages/project/_id/preview.vue' /* webpackChunkName: "pages/project/_id/preview" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _368b5adc,
    caseSensitive: true,
    name: "auth"
  }, {
    path: "/blog",
    component: _999675e2,
    caseSensitive: true,
    name: "blog"
  }, {
    path: "/contacts",
    component: _12bb0ebd,
    caseSensitive: true,
    name: "contacts"
  }, {
    path: "/documents",
    component: _4c92363e,
    caseSensitive: true,
    name: "documents"
  }, {
    path: "/elements",
    component: _b735053e,
    caseSensitive: true,
    name: "elements"
  }, {
    path: "/faq",
    component: _41d65b9f,
    caseSensitive: true,
    name: "faq"
  }, {
    path: "/investor",
    component: _66d20882,
    caseSensitive: true,
    name: "investor"
  }, {
    path: "/logout",
    component: _49c57cf4,
    caseSensitive: true,
    name: "logout"
  }, {
    path: "/register",
    component: _5fba350d,
    caseSensitive: true,
    name: "register"
  }, {
    path: "/register-email-link",
    component: _1ec0dc7b,
    caseSensitive: true,
    name: "register-email-link"
  }, {
    path: "/register-type",
    component: _738b56aa,
    caseSensitive: true,
    name: "register-type"
  }, {
    path: "/registration-second",
    component: _82f1c3e4,
    caseSensitive: true,
    name: "registration-second"
  }, {
    path: "/registration-success",
    component: _8cdc4f4e,
    caseSensitive: true,
    name: "registration-success"
  }, {
    path: "/sign-up-entity",
    component: _80c979a2,
    caseSensitive: true,
    name: "sign-up-entity"
  }, {
    path: "/vitrina",
    component: _42e3bf51,
    caseSensitive: true,
    name: "vitrina"
  }, {
    path: "/email/verify",
    component: _172d2736,
    caseSensitive: true,
    name: "email-verify"
  }, {
    path: "/profile/balance",
    component: _991b3308,
    caseSensitive: true,
    children: [{
      path: "",
      component: _44df1fff,
      name: "profile-balance"
    }, {
      path: "replenishment",
      component: _d845669e,
      name: "profile-balance-replenishment"
    }, {
      path: "transfer",
      component: _18c01b4e,
      name: "profile-balance-transfer"
    }]
  }, {
    path: "/profile/create-profile",
    component: _4ff04c68,
    caseSensitive: true,
    name: "profile-create-profile"
  }, {
    path: "/profile/dashboard",
    component: _6f30b798,
    caseSensitive: true,
    name: "profile-dashboard"
  }, {
    path: "/profile/documents",
    component: _51a15a58,
    caseSensitive: true,
    name: "profile-documents"
  }, {
    path: "/profile/favourites",
    component: _83d55b0c,
    caseSensitive: true,
    name: "profile-favourites"
  }, {
    path: "/profile/my-offers",
    component: _59b70c78,
    caseSensitive: true,
    name: "profile-my-offers"
  }, {
    path: "/profile/my-orders",
    component: _7d29e6c6,
    caseSensitive: true,
    name: "profile-my-orders"
  }, {
    path: "/profile/my-profiles",
    component: _39c3570b,
    caseSensitive: true,
    name: "profile-my-profiles"
  }, {
    path: "/profile/oises",
    component: _65901467,
    caseSensitive: true,
    name: "profile-oises"
  }, {
    path: "/profile/order-detail",
    component: _3539ea7a,
    caseSensitive: true,
    name: "profile-order-detail",
    children: [{
      path: ":id?/documents",
      component: _9f86f352,
      name: "profile-order-detail-index-id-documents"
    }, {
      path: ":id?/investors",
      component: _4a760e0c,
      name: "profile-order-detail-index-id-investors"
    }, {
      path: ":id?/project",
      component: _306bfbf8,
      name: "profile-order-detail-index-id-project"
    }, {
      path: ":id?/repayment",
      component: _344e3292,
      name: "profile-order-detail-index-id-repayment"
    }, {
      path: ":id?/schedule",
      component: _260f7070,
      name: "profile-order-detail-index-id-schedule"
    }]
  }, {
    path: "/profile/support",
    component: _036a459c,
    caseSensitive: true,
    name: "profile-support"
  }, {
    path: "/profile/application/create",
    component: _50d807ab,
    caseSensitive: true,
    name: "profile-application-create"
  }, {
    path: "/profile/ois/create",
    component: _31c65782,
    caseSensitive: true,
    name: "profile-ois-create"
  }, {
    path: "/profile/support/create",
    component: _1a75d868,
    caseSensitive: true,
    name: "profile-support-create"
  }, {
    path: "/profile/support/success",
    component: _3a5061e3,
    caseSensitive: true,
    name: "profile-support-success"
  }, {
    path: "/profile/entity-profile/:id?",
    component: _71c08a0c,
    caseSensitive: true,
    name: "profile-entity-profile-id"
  }, {
    path: "/profile/individual-profile/:id?",
    component: _117a91b0,
    caseSensitive: true,
    name: "profile-individual-profile-id"
  }, {
    path: "/profile/ois/:id?",
    component: _cc1e67b8,
    caseSensitive: true,
    name: "profile-ois-id"
  }, {
    path: "/profile/personal-profile/:id?",
    component: _5120f552,
    caseSensitive: true,
    name: "profile-personal-profile-id"
  }, {
    path: "/profile/support/:ticket",
    component: _4353360b,
    caseSensitive: true,
    name: "profile-support-ticket"
  }, {
    path: "/profile/application/:id?/edit",
    component: _4fd6b4ae,
    caseSensitive: true,
    name: "profile-application-id-edit"
  }, {
    path: "/blog/:slug",
    component: _9d03b872,
    caseSensitive: true,
    name: "blog-slug"
  }, {
    path: "/faq/:slug",
    component: _401fba57,
    caseSensitive: true,
    name: "faq-slug"
  }, {
    path: "/project/:slug?",
    component: _1a006b9a,
    caseSensitive: true,
    name: "project-slug"
  }, {
    path: "/project/:id?/preview",
    component: _7b66aa03,
    caseSensitive: true,
    name: "project-id-preview"
  }, {
    path: "/",
    component: _2dfb1658,
    caseSensitive: true,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
