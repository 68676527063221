import { FAVORITE_ADD, FAVORITE_REMOVE, FAVORITE_MY, FAVORITE_COUNT} from '~/const/api/favorite';

const state = () => ({
    project: null,
    schedule: null,
})

const getters = {
    
}

const mutations = {
    SET_PROJECT(state, project) {
        state.project = project
    },
    SET_SCHEDULE(state, data) {
        state.schedule = data
    }
}

const actions = {
    cleanOrder({dispatch}) {
        dispatch('setProject', null)
        dispatch('setSchedule', null)
    },
    setProject({commit}, data) {
        commit('SET_PROJECT', data)
    },
    setSchedule({commit}, data) {
        commit('SET_SCHEDULE', data)
    },
    async getLoanById({dispatch}, id) {
        const res = await this.$axios.get(`/my/${id}/loan`)
        dispatch('setProject', res.data.data)
        return res
    },
    async getLoanSchedule({dispatch}, id) {
        const res = await this.$axios.get('/schedule/loan', {
            params: {
                uuid: id
            }
        })
        dispatch('setSchedule', res.data.item)
        return res
    },
    async getPaymentsCalculator({dispatch}, data) {
        const res = await this.$axios.post(`/payments-calculator/${data.id}`, {
            amount: data.amount
        })
        dispatch('setSchedule', res.data.item)
        return res
    },
    async getInvestSchedule({dispatch}, id) {
        const res = await this.$axios.get('/schedule/invest', {
            params: {
                uuid: id,
            }
        })
        dispatch('setSchedule', res.data.item)
        return res
    },
    async calculateFullPayment({commit}, params) {
        const res = await this.$axios.get(`/prepayment/calculate/${params.applicationId}/full`, {
            params: {
                prepayment_mode: params.prepayment_mode,
                date: params.date,
                is_full : params.is_full
            }
        })
        return res
    },
    async createPrepayment({commit}, params) {
        const res = await this.$axios.post(`/prepayment/create-prepayment/${params.applicationId}`, {
            prepayment_mode: params.prepayment_mode,
            payment: params.payment,
            date: params.date,
            is_full : params.is_full
        })
        return res
    },
    async getLoanScheduleCalculation({commit}, params) {
        const res = await this.$axios.get(`/prepayment/calculate/${params.applicationId}/`, {
            params: {
                prepayment_mode: params.prepayment_mode,
                payment: params.payment,
                date: params.date,
                is_full: params.is_full
            },
        });
        return res
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
