import { render, staticRenderFns } from "./ModalProjectInvestCancelConfirm.vue?vue&type=template&id=346ce234&scoped=true&"
import script from "./ModalProjectInvestCancelConfirm.vue?vue&type=script&lang=js&"
export * from "./ModalProjectInvestCancelConfirm.vue?vue&type=script&lang=js&"
import style0 from "./ModalProjectInvestCancelConfirm.vue?vue&type=style&index=0&id=346ce234&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "346ce234",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementLoader: require('/app/components/element/Loader.vue').default,UiVFormGroup: require('/app/components/ui/VFormGroup.vue').default})
