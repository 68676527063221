
import ModalProjectInvestCancelConfirm from '@/components/modals/ModalProjectInvestCancelConfirm'
import ModalProjectInvestConfirm from '@/components/modals/ModalProjectInvestConfirm'
import ModalProjectInvestCancel from '@/components/modals/ModalProjectInvestCancel'
import ModalProjectInvest from '@/components/modals/ModalProjectInvest'
import ModalSpecialFund from '@/components/modals/ModalSpecialFund'
import ModalSmsConfirm from '@/components/modals/ModalSmsConfirm'
import ModalDefault from '@/components/modals/ModalDefault'


export default {
    components: {
        ModalProjectInvestCancelConfirm,
        ModalProjectInvestConfirm,
        ModalProjectInvestCancel,
        ModalProjectInvest,
        ModalSpecialFund,
        ModalSmsConfirm,
        ModalDefault,
    },
}
