import _ from 'lodash'
import { UploadData } from '~/services/UploadUtils'

const isFilled = (object) => {
    return Object.keys(object).every((key) => {
        if (object[key].value !== undefined) {
            if (object[key].error) {
                return false
            }
            if (object[key].required) {
                if (object[key].files) {
                    return !!object[key].value.length
                }
                return !!object[key].value
            }
            return true
        }
        return isFilled(object[key])
    })
}

function datesEqual(dateOne, dateTwo) {
    if (dateOne && dateTwo) {
        return dateOne === dateTwo
    }
    return false;
}

let dateTimeReviver = function (key, value) {
    var a;
    if (typeof value === 'string') {
        a = /\/Date\((\d*)\)\//.exec(value);
        if (a) {
            return new Date(+a[1]);
        }
    }
    return value;
}

let isDate = function(value) {
    switch (typeof value) {
        case 'number':
            return true;
        case 'string':
            return !isNaN(Date.parse(value));
        case 'object':
            if (value instanceof Date) {
                return !isNaN(value.getTime());
            }
        default:
            return false;
    }
}

let postData = {}

const state = () => ({
    stepOneId: null,
    data: {
        signToken: null,
        entity: {
            customer_is_owner: {
                value: false,
                required: false,
                error: '',
            },
            foreign_taxpayer: {
                value: false,
                required: true,
                error: '',
            },
            equal_addresses: {
                value: false,
                required: false,
                error: ''
            },
            organization_full_name: {
                value: '',
                required: true,
                error: '',
                step: 'one'
            },
            organization_short_name: {
                value: '',
                required: true,
                error: '',
                step: 'one'
            },
            kpp: {
                value: '',
                required: true,
                error: '',
                step: 'one'
            },
            legal_address: {
                value: '',
                required: true,
                error: '',
                step: 'one'
            },
            legal_address_index: {
                value: '',
                required: false,
                error: '',
            },
            legal_address_country: {
                value: '',
                required: true,
                error: '',
                step: 'one'
            },
            legal_address_region: {
                value: '',
                required: true,
                error: '',
                step: 'one'
            },
            legal_address_city: {
                value: '',
                required: false,
                error: '',
            },
            legal_address_street: {
                value: '',
                required: false,
                error: '',
                step: 'one'
            },
            legal_address_building: {
                value: '',
                required: false,
                error: '',
                step: 'one'
            },
            legal_address_apartment: {
                value: '',
                required: false,
                error: '',
            },
            actual_address: {
                value: '',
                required: true,
                error: '',
                step: 'one'
            },
            actual_address_country: {
                value: '',
                required: true,
                error: '',
                step: 'one'
            },
            actual_address_region: {
                value: '',
                required: true,
                error: '',
                step: 'one'
            },
            actual_address_city: {
                value: '',
                required: false,
                error: '',
                step: 'one'
            },
            actual_address_street: {
                value: '',
                required: false,
                error: '',
                step: 'one'
            },
            actual_address_building: {
                value: '',
                required: false,
                error: '',
                step: 'one'
            },
            actual_address_apartment: {
                value: '',
                required: false,
                error: '',
                step: 'one'
            },
            custom_actual_address: {
                value: false,
                required: false,
                error: ''
            },
            phone_number: {
                value: '',
                required: true,
                error: '',
                step: 'one'
            },
            registration_date: {
                value: '',
                required: true,
                error: '',
            },
            ogrn: {
                value: '',
                required: true,
                error: '',
                step: 'one',
                rule() {
                    return this.value.length === 13
                },
                ruleMessage: 'ОГРН должен состоять из 13 цифр',
            },
            inn: {
                value: '',
                required: true,
                error: '',
                step: 'one',
                rule() {
                    return this.value.length === 10
                },
                ruleMessage: 'ИНН должен состоять из 10 цифр',
            },
            organization_email: {
                value: '',
                required: false,
                error: '',
                rule() {
                    return /\S+@\S+\.\S+/.test(this.value)
                },
                ruleMessage: 'Введите корректный E-mail адрес',
            },
            site: {
                value: '',
                required: false,
                error: '',
            },
            articles_of_association_documents: {
                value: [],
                required: true,
                error: '',
                files: [],
                step: 'one',
            },
            licenses_documents: {
                value: [],
                required: false,
                error: '',
                files: [],
                step: 'one',
            },
            statutory_documents: {
                value: [],
                required: false,
                error: '',
                files: [],
            },
            confirming_documents: {
                value: [],
                required: true,
                error: '',
                files: [],
                step: 'one',
            },
            structure_info: [{
                members: [{
                    name: {
                        value: '',
                        required: false,
                        error: ''
                    },
                    patronymic: {
                        value: '',
                        required: false,
                        error: ''
                    },
                    percent: {
                        value: '',
                        required: false,
                        error: ''
                    },
                    surname: {
                        value: '',
                        required: false,
                        error: ''
                    },
                    type: {
                        value: '',
                        required: false,
                        error: ''
                    }
                }],
                type: {
                    value: '',
                    required: true,
                    error: ''
                }
            }],
            financial_info: {
                value: false,
                required: false,
                error: '',
                step: 'four',
            },
            financial_info_documents: {
                value: [],
                required: false,
                error: '',
                files: [],
                step: 'four',
            },
            financial_source_info_array: {
                value: [],
                required: false,
                error: '',
                files: [],
                step: 'four',
            },
            financial_source_info_other: {
                value: '',
                required: false,
                error: '',
                step: 'four',
            },
            tax_system: {
                value: '',
                required: false,
                error: '',
                step: 'four',
            },
            tax_system_documents: {
                value: [],
                required: false,
                error: '',
                files: [],
                step: 'four',
            },
            risk_bool: {
                value: false,
                required: true,
                error: '',
                step: 'four',
            },
            reputation_info_bool: {
                value: true,
                required: false,
                error: '',
                step: 'four',
            },
            reputation_info_documents: {
                value: [],
                required: false,
                error: '',
                files: [],
                step: 'four',
            },
            extra_data: {
                capital_value: {
                    value: '',
                    required: false,
                    error: '',
                },
                email: {
                    value: '',
                    required: false,
                    error: '',
                },
                founders: {
                    value: '',
                    required: false,
                    error: '',
                },
                full_opf: {
                    value: '',
                    required: true,
                    error: '',
                },
                short_opf: {
                    value: '',
                    required: false,
                    error: '',
                    step: 'one'
                },
                licenses: {
                    value: '',
                    required: false,
                    error: '',
                },
                okveds: {
                    value: '',
                    required: false,
                    error: '',
                },
                managers: {
                    value: '',
                    required: false,
                    error: '',
                },
                authorities: {
                    value: '',
                    required: false,
                    error: '',
                },
                mailing_address: {
                    value: '',
                    required: false,
                    error: '',
                },
                management_name: {
                    value: '',
                    required: false,
                    error: '',
                },
                management_post: {
                    value: '',
                    required: false,
                    error: '',
                },
                ogrn_document_number: {
                    value: '',
                    required: false,
                    error: '',
                },
                okato: {
                    value: '',
                    required: false,
                    error: '',
                },
                okpo: {
                    value: '',
                    required: false,
                    error: '',
                },
                okved: {
                    value: '',
                    required: false,
                    error: '',
                },
                registration_date: {
                    value: '',
                    required: false,
                    error: '',
                },
            },
            owners_info: [{
                russian_citizen: {
                    value: true,
                    required: false,
                    error: '',
                    step: 'three'
                },
                opened: {
                    value: true,
                    required: false,
                    error: '',
                    step: 'three',
                },
                agreement1: {
                    value: false,
                    required: true,
                    error: '',
                    step: 'three',
                },
                agreement2: {
                    value: false,
                    required: true,
                    error: '',
                    step: 'three',
                },
                surname: {
                    value: '',
                    required: true,
                    error: '',
                    step: 'three',
                },
                name: {
                    value: '',
                    required: true,
                    error: '',
                    step: 'three',
                },
                patronymic: {
                    value: '',
                    required: false,
                    error: '',
                    step: 'three',
                },
                sex: {
                    value: '',
                    required: true,
                    error: '',
                    step: 'three',
                },
                birthday: {
                    value: '',
                    required: true,
                    error: '',
                    step: 'three',
                },
                place_of_birth: {
                    value: '',
                    required: true,
                    error: '',
                    step: 'three',
                },
                passport_number: {
                    value: '',
                    required: true,
                    error: '',
                    step: 'three',
                    rule() {
                        return this.value.length === 10
                    },
                    ruleMessage:
                        'Номер паспорта должен состоять из 10 цифр',
                },
                passport_issued_by: {
                    value: '',
                    required: true,
                    error: '',
                    step: 'three',
                },
                passport_issued_when: {
                    value: '',
                    required: true,
                    error: '',
                    step: 'three',
                },
                passport_issued_unit_code: {
                    value: '',
                    required: true,
                    error: '',
                    step: 'three',
                    rule() {
                        return this.value.length === 6
                    },
                    ruleMessage:
                        'Код подразделения должен состоять из 6 цифр',
                },
                passport_scans: {
                    value: [],
                    required: false,
                    error: '',
                    step: 'three',
                    files: [],
                },
                registration_address: {
                    value: '',
                    required: true,
                    error: '',
                    step: 'three',
                },
                inn: {
                    value: '',
                    required: false,
                    error: '',
                    step: 'three',
                    rule() {
                        return (this.value && this.value.length) ? this.value.length === 12 : true
                    },
                    ruleMessage: 'ИНН должен состоять из 12 цифр',
                },
                snils: {
                    value: '',
                    required: false,
                    error: '',
                    step: 'three',
                    rule() {
                        return (this.value && this.value.length) ? this.value.length === 14 : true
                    },
                    ruleMessage: 'СНИЛС должен состоять из 11 цифр',
                },
                email: {
                    value: '',
                    required: false,
                    error: '',
                    step: 'three',
                    rule() {
                        return /\S+@\S+\.\S+/.test(this.value)
                    },
                    ruleMessage: 'Введите корректный E-mail адрес',
                },
                phone_number: {
                    value: '',
                    required: false,
                    error: '',
                    step: 'three',
                },
                index: {
                    value: '',
                    required: false,
                    error: '',
                    step: 'three',
                },
                custom_address: {
                    value: false,
                    required: false,
                    error: '',
                    step: 'three',
                },
                country: {
                    value: '',
                    required: false,
                    error: '',
                },
                region: {
                    value: '',
                    required: false,
                    error: '',
                    step: 'three',
                },
                city: {
                    value: '',
                    required: false,
                    error: '',
                    step: 'three',
                },
                street: {
                    value: '',
                    required: false,
                    error: '',
                    step: 'three',
                },
                building: {
                    value: '',
                    required: false,
                    error: '',
                    step: 'three',
                },
                apartment: {
                    value: '',
                    required: false,
                    error: '',
                    step: 'three',
                },
                uuid: {
                    value: '',
                    required: true,
                    error: '',
                    step: 'three'
                }
            }],
        },
        person: {
            full_name: {
                value: '',
                required: true,
                error: '',
                step: 'two',
            },
            gender: {
                value: '',
                required: true,
                error: '',
                step: 'two',
            },
            birthday: {
                value: '',
                required: true,
                error: '',
                step: 'two',
            },
            serial_number: {
                value: '',
                required: true,
                error: '',
                step: 'two',
            },
            issued_unit_code: {
                value: '',
                required: true,
                error: '',
                step: 'two',
            },
            issued_by: {
                value: '',
                required: true,
                error: '',
                step: 'two',
            },
            issued_when: {
                value: '',
                required: true,
                error: '',
                step: 'two',
            },
            is_not_pdl: {
                value: false,
                required: true,
                error: '',
                step: 'two',
            },
            is_not_foreign_tax_payer: {
                value: false,
                required: true,
                error: '',
                step: 'two',
            },
            place_of_birth: {
                value: '',
                required: true,
                error: '',
                step: 'two',
            },
            serial_number: {
                value: '',
                required: true,
                error: '',
                step: 'two',
                rule() {
                    return this.value.length === 10
                },
                ruleMessage: 'Номер паспорта должен состоять из 10 цифр',
            },
            issued_by: {
                value: '',
                required: true,
                error: '',
                step: 'two',
            },
            issued_when: {
                value: '',
                required: true,
                error: '',
                step: 'two',
            },
            issued_unit_code: {
                value: '',
                required: true,
                error: '',
                step: 'two',
                rule() {
                    return this.value.length === 6
                },
                ruleMessage: 'Код подразделения должен состоять из 6 цифр',
            },
            equal_addresses: {
                value: false,
                required: false,
                error: '',
                step: 'two',
            },
            registration_address: {
                value: '',
                required: true,
                error: '',
                step: 'two',
            },
            head_position: {
                value: 'Генеральный директор',
                required: true,
                error: '',
                step: 'two',
            },
            passport_scans: {
                value: [],
                required: true,
                error: '',
                files: [],
                step: 'two',
            },
            phone_number: {
                value: '',
                required: true,
                error: '',
                step: 'two',
            },
            email: {
                value: '',
                required: true,
                error: '',
                step: 'two',
            },
            inn: {
                value: '',
                required: true,
                error: '',
                step: 'two',
                rule() {
                    return this.value.length === 12
                },
                ruleMessage: 'ИНН должен состоять из 12 цифр',
            },
            snils: {
                value: '',
                required: false,
                error: '',
                step: 'two',
                rule() {
                    return (this.value && this.value.length) ? this.value.length === 14 : true
                },
                ruleMessage: 'СНИЛС должен состоять из 11 цифр',
            },
            custom_address: {
                value: false,
                required: false,
                error: ''
            },
            custom_actual_address: {
                value: false,
                required: false,
                error: ''
            },
            index: {
                value: '',
                required: false,
                error: '',
                step: 'two',
            },
            country: {
                value: '',
                required: false,
                error: '',
                step: 'two',
            },
            region: {
                value: '',
                required: false,
                error: '',
                step: 'two',
            },
            city: {
                value: '',
                required: false,
                error: '',
                step: 'two',
            },
            street: {
                value: '',
                required: false,
                error: '',
                step: 'two',
            },
            building: {
                value: '',
                required: false,
                error: '',
                step: 'two',
            },
            apartment: {
                value: '',
                required: false,
                error: '',
                step: 'two',
            },
            actual_address: {
                value: '',
                required: false,
                error: '',
                step: 'two',
            },
            actual_address_country: {
                value: '',
                required: false,
                error: '',
                step: 'two',
            },
            actual_address_region: {
                value: '',
                required: false,
                error: '',
                step: 'two',
            },
            actual_address_city: {
                value: '',
                required: false,
                error: '',
                step: 'two',
            },
            actual_address_street: {
                value: '',
                required: false,
                error: '',
                step: 'two'
            },
            actual_address_building: {
                value: '',
                required: false,
                error: '',
                step: 'two'
            },
            actual_address_apartment: {
                value: '',
                required: false,
                error: '',
                step: 'two',
            },
        },
    },
    localFiles: {
        associationDocuments: [],
        statutoryDocuments: [],
        confirmingDocuments: [],
        taxSystemFile: [],
        financialInfoFile: [],
        reputationInfoFile: [],
        passportScans: []
    },
    autosaveDataReceived: false,
})

const getters = {
    // threeStepIsValid: (state) => {
    //     return state.data.entity.owners_info.value.every((el) => {
    //         return isFilled(el) && el.passport_scans.value.length && !datesEqual(el.birthday.value, el.passport_issued_when.value)
    //     })
    // },
    personIsValid: (state) => {
        return isFilled(state.data.person)
    },
    threeStepIsValid: (state) => {
        return state.data.entity.financial_source_info_array.value.length &&
               (!state.data.entity.financial_source_info_other.required ||
                (state.data.entity.financial_source_info_other.required && !!state.data.entity.financial_source_info_other.value))
    },
    twoStepIsValid: (state, getters) => {
        return (
            state.data.entity.structure_info.length &&
            state.data.entity.structure_info.every((el) => {
                if (el.type.value.includes('Единоличный исполнительный орган')) {
                    return (
                        !!el.type.value &&
                        el.members.every((el) => {
                            if (el.type.value === 1) {
                                return !!el.name.value && !!el.surname.value
                            } else {
                                return !!el.name.value
                            }
                        })
                    )
                } else if (el.type.value.includes('Коллегиальный исполнительный орган')) {
                    return (
                        !!el.type.value &&
                        el.members.every((el) => {
                            return !!el.name.value
                        })
                    )
                } else if (el.type.value.includes('Совет директоров')) {
                    return (
                        !!el.type.value &&
                        el.members.every((el) => {
                            if (el.type.value === 1) {
                                return !!el.name.value && !!el.surname.value
                            } else {
                                return !!el.name.value
                            }
                        })
                    )
                } else {
                    return (
                        !!el.type &&
                        el.members.every((el) => {
                            if (el.type === 1) {
                                return !!el.name.value && !!el.surname.value && !!el.percent.value
                            } else {
                                return !!el.name.value && !!el.percent.value
                            }
                        })
                    )
                }
            }) &&
            state.data.entity.owners_info.every((el) => {
                return isFilled(el) && !datesEqual(el.birthday.value, el.passport_issued_when.value)
            })
        )
    },
    firstStepIsValid: (state, getters) => {
        return (
            state.data.entity.organization_full_name.value &&
            !state.data.entity.organization_full_name.error &&
            state.data.entity.inn.value &&
            !state.data.entity.inn.error &&
            state.data.entity.ogrn.value &&
            !state.data.entity.ogrn.error &&
            state.data.entity.articles_of_association_documents.value.length &&
            state.data.entity.confirming_documents.value.length &&
            getters.extraDataIsFilled &&
            getters.legalAddressIsFilled &&
            getters.actualAddressIsFilled
        )
    },
    extraDataIsFilled: (state) => {
        return (
            !!state.data.entity.extra_data.short_opf.value &&
            !!state.data.entity.extra_data.full_opf.value
        )
    },
    legalAddressIsFilled: (state) => {
        return (
            !!state.data.entity.legal_address_country.value &&
            !!state.data.entity.legal_address_region.value
        )
    },
    legalAddressIsFullForActual: (state) => {
        return (
            !!state.data.entity.legal_address_country.value &&
            !!state.data.entity.legal_address_region.value
        )
    },
    actualAddressIsFilled: (state) => {
        return (
            !!state.data.entity.actual_address_country.value &&
            !!state.data.entity.actual_address_region.value
        )
    },
    ownerAddressIsFilled: (state) => (index) => {
        return (
            !!state.data.entity.owners_info[index].country.value &&
            !!state.data.entity.owners_info[index].region.value
        )
    },
    customerAddressIsFilled: (state) => {
        return (
            !!state.data.person.country.value &&
            !!state.data.person.region.value
        )
    },
    customerActualAddressIsFilled: (state) => {
        return (
            !!state.data.person.actual_address_country.value &&
            !!state.data.person.actual_address_region.value
        )
    },
}

const mutations = {
    SET_AUTOSAVE_DATA_RECEIVED(state, value) {
        state.autosaveDataReceived = value
    },
    CHANGE_DATA(state, data) {
        if (_.get(state.data, data.key) !== undefined) {
            _.set(state.data, data.key, data.value)
        }
    },
    ADD_TO_ARRAY(state, data) {
        _.get(state.data, data.key).push(data.el)
    },
    SET_LOCAL_FILES(state, data) {
        state.localFiles[data.key] = data.value
    },
    ADD_LOCAL_FILE(state, data) {
        state.localFiles[data.key].push(data.file)
    },
    REMOVE_LOCAL_FILE(state, data) {
        _.get(state.data, data.key).files.splice(data.index, 1)
    },
    ADD_STRUCTURE_INFO_ITEM(state, item) {
        state.data.entity.structure_info.push({
            type: item.type,
            members: []
        })
        item.members.forEach(element => {
            state.data.entity.structure_info[state.data.entity.structure_info.length - 1].members.push(element)
        });
    },
    SET_DEFAULT_MEMBER_STRUCTURE_INFO_VALUE(state, itemIndex) {
        state.data.entity.structure_info[itemIndex].members = [
            {
                type: {
                    value: 1,
                    error: '',
                    required: true,
                    step: 'two'
                }, // 1 - Физ. лицо, 2 - Юр. лицо
                name: {
                    value: '',
                    error: '',
                    required: true,
                    step: 'two'
                }, // Имя или название организации если это юр. лицо
                surname: {
                    value: '',
                    error: '',
                    required: true,
                    step: 'two'
                },
                patronymic: {
                    value: '',
                    error: '',
                    required: false,
                    step: 'two'
                },
                percent: {
                    value: '',
                    error: '',
                    required: true,
                    step: 'two'
                }
            }
        ]
    },
    ADD_MEMBER_STRUCTURE_INFO_ITEM(state, data) {
        state.data.entity.structure_info[data.itemIndex].members.push(
            data.member
        )
    },
    DELETE_STRUCTURE_INFO_ITEM(state, itemIndex) {
        state.data.entity.structure_info.splice(itemIndex, 1)
    },
    DELETE_MEMBER_STRUCTURE_INFO_ITEM(state, data) {
        state.data.entity.structure_info[data.itemIndex].members.splice(
            data.memberIndex,
            1
        )
    },
    SET_STRUCTURE_INFO_VALUE(state, data) {
        state.data.entity.structure_info[data.itemIndex][data.key].value = data.value
    },
    SET_MEMBER_STRUCTURE_INFO_VALUE(state, data) {
        if (data.value !== undefined) {
            state.data.entity.structure_info[data.itemIndex].members[
                data.memberIndex
            ][data.key].value = data.value
        }
        if (data.required !== undefined) {
            state.data.entity.structure_info[data.itemIndex].members[
                data.memberIndex
            ][data.key].required = data.required
        }
        state.data.entity.structure_info[data.itemIndex].members[
            data.memberIndex
        ][data.key].error = ''
    },
    ADD_OWNERS_INFO_ITEM(state) {
        let clone = _.cloneDeep(state.data.entity.owners_info[0])
        Object.keys(clone).forEach((key) => {
            if (key === 'opened') {
                clone[key].value = true
            } else if (key === 'agreement1' || key === 'agreement2') {
                clone[key].value = false
            } else if (key === 'passport_scans') {
                clone[key].value = []
                clone[key].files = []
            } else if (key === 'custom_address') {
                clone[key].value = false
            } else {
                clone[key].value = ''
            }
            if (clone[key].files !== undefined) {
                clone[key].files = []
            }
            clone[key].error = ''
        })
        state.data.entity.owners_info.push(clone)
    },
    DELETE_OWNERS_INFO_ITEM(state, index) {
        state.data.entity.owners_info.splice(index, 1)
    },
    SET_STEP_ID(state, id) {
        state.stepOneId = id
    },
    SET_DATA(state, data) {
        const setData = (data, path) => {
            Object.keys(_.get(state, path)).map((key) => {
                if (key === 'signToken') {
                    _.set(state, `${path}.${key}`, data[key]);
                } else if (_.get(state, `${path}.${key}`).value !== undefined) {
                    if (data[key] !== undefined) {
                        if (key === 'head_position' && !data[key].value) {
                            _.set(state, `${path}.${key}.value`, 'Генеральный директор')
                        } else {
                            _.set(state, `${path}.${key}.value`, data[key].value)
                        }
                        if (_.get(state, `${path}.${key}.files`)) {
                            _.set(state, `${path}.${key}.files`, data[key].files)
                        }
                    }
                } else {
                    setData(_.get(data, key), `${path}.${key}`)
                }
            })
        }
        setData(data, 'data')
    },
    SET_TOKEN(state, id) {
        state.data.signToken = id;
    },
    PREPARE_OWNERS_INFO_DATA(state, data) {
        let structure = _.cloneDeep(state.data.entity.owners_info[0])
        state.data.entity.owners_info = []
        data.forEach(element => {
            state.data.entity.owners_info.push(_.cloneDeep(structure))
        })
    },
    PREPARE_STRUCTURE_INFO_DATA(state, data) {
        state.data.entity.structure_info = []
        data.forEach(element => {
            let members = []
            element.members.forEach(member => {
                members.push({
                    name: {
                        value: '',
                        required: false,
                        error: ''
                    },
                    patronymic: {
                        value: '',
                        required: false,
                        error: ''
                    },
                    percent: {
                        value: '',
                        required: false,
                        error: ''
                    },
                    surname: {
                        value: '',
                        required: false,
                        error: ''
                    },
                    type: {
                        value: '',
                        required: false,
                        error: ''
                    }
                })
            })
            state.data.entity.structure_info.push({
                members: members,
                type: {
                    value: '',
                    required: true,
                    error: ''
                }
            })
        });
    }
}

const actions = {
    autosaveUpdate: _.debounce(function ({dispatch}) {
        this.$axios.post('/register/autosave/update', {
            data: this.JSONfn.stringify(state.data),
            id: state.stepOneId
        })
    }, 500),
    async getStepId({commit}) {
        let res = await this.$axios.post('/register/autosave/legal/first').then(res => {
            commit('SET_STEP_ID', res.data.items.legal_step_1)
        })
        return res
    },
    async getAutosaveData({commit, dispatch}) {
        let res = await this.$axios.get(`/register/autosave/get/${state.stepOneId}`).then(res => {
            const item = res.data.item[0];
            if (item && item !== 'null') {
                const data = this.JSONfn.parse(item, dateTimeReviver)
                commit('SET_DATA', data);
                if (data.signToken) dispatch('auth/setSignToken', data.signToken, { root: true });
            } else {
                dispatch('profile/createToken', null, {root: true}).createToken().then(({ data: { item: { id } } }) => {
                    dispatch('auth/setSignToken', id, { root: true });
                    commit('SET_TOKEN', id);
                });
            }
            commit('SET_AUTOSAVE_DATA_RECEIVED', true)
        })
        return res
    },
    async getStepAutosaveData({dispatch}) {
        await dispatch('getStepId')
        await dispatch('getAutosaveData')
    },
    setStructureInfoValue({ commit }, data) {
        commit('SET_STRUCTURE_INFO_VALUE', data)
    },
    setDefaultMemberStructureInfoValue({ commit }, itemIndex) {
        commit('SET_DEFAULT_MEMBER_STRUCTURE_INFO_VALUE', itemIndex);
    },
    setMemberStructureInfoValue({ commit }, data) {
        commit('SET_MEMBER_STRUCTURE_INFO_VALUE', data)
    },
    prepareOwnersInfoData({commit}, data) {
        commit('PREPARE_OWNERS_INFO_DATA', data)
    },
    prepareStructureInfoData({commit}, data) {
        commit('PREPARE_STRUCTURE_INFO_DATA', data)
    },
    addStructureInfoItem({ commit }, required = null) {
        let requiredDefault = {
            name: required ? required.name : true,
            surname: required ? required.surname : true,
            percent: required ? required.percent : true,
        }
        commit('ADD_STRUCTURE_INFO_ITEM', {
            type: {
                value: '',
                error: '',
                required: true,
                step: 'two'
            },
            members: [
                {
                    type: {
                        value: 1,
                        error: '',
                        required: true,
                        step: 'two'
                    }, // 1 - Физ. лицо, 2 - Юр. лицо
                    name: {
                        value: '',
                        error: '',
                        required: requiredDefault.name,
                        step: 'two'
                    }, // Имя или название организации если это юр. лицо
                    surname: {
                        value: '',
                        error: '',
                        required: requiredDefault.surname,
                        step: 'two'
                    },
                    patronymic: {
                        value: '',
                        error: '',
                        required: false,
                        step: 'two'
                    },
                    percent: {
                        value: '',
                        error: '',
                        required: requiredDefault.percent,
                        step: 'two'
                    },
                },
            ],
        })
    },
    deleteStructureInfoItem({ commit }, itemIndex) {
        commit('DELETE_STRUCTURE_INFO_ITEM', itemIndex)
    },
    addMemberStructureInfoItem({ commit }, data) {
        commit('ADD_MEMBER_STRUCTURE_INFO_ITEM', {
            itemIndex: data.index,
            member: {
                type: {
                    value: data.type,
                    error: '',
                    required: true,
                    step: 'two'
                }, // 1 - Физ. лицо, 2 - Юр. лицо
                name: {
                    value: '',
                    error: '',
                    required: true,
                    step: 'two'
                }, // Имя или название организации если это юр. лицо
                surname: {
                    value: '',
                    error: '',
                    required: true,
                    step: 'two'
                },
                patronymic: {
                    value: '',
                    error: '',
                    required: false,
                    step: 'two'
                },
                percent: {
                    value: '',
                    error: '',
                    required: true,
                    step: 'two'
                },
            },
        })
    },
    deleteMemberStructureInfoItem({ commit }, data) {
        commit('DELETE_MEMBER_STRUCTURE_INFO_ITEM', data)
    },
    compileErrors({ dispatch }, data) {
        Object.keys(data.errors).map((key) => {
            if (Array.isArray(data.errors[key])) {
                if (typeof data.errors[key][0] === 'object') {
                    dispatch('compileErrors', {
                        errors: data.errors[key],
                        path: `${data.path}.${key}.value`,
                    })
                } else {
                    if (key.includes('owners_info')) {
                        dispatch('changeDataValue', {
                            key: `${key}.error`.replace('owners_info', 'owners_info'),
                            value: data.errors[key][0],
                        })
                    } else if (key.includes('structure_info')) {
                        dispatch('changeDataValue', {
                            key: `${key}.error`.replace('structure_info', 'structure_info').replace('members', 'members'),
                            value: data.errors[key][0],
                        })
                    } else {
                        dispatch('changeDataValue', {
                            key: `${key}.error`,
                            value: data.errors[key][0],
                        })
                    }
                }
            } else {
                dispatch('compileErrors', {
                    errors: data.errors[key],
                    path: `${data.path}.${key}`,
                })
            }
        }, {})
    },
    validateStep({ dispatch }, data) {
        Object.keys(data.object).map((key) => {
            if (data.object[key]) {
                if (data.object[key].value !== undefined && Array.isArray(data.object[key].value) && data.object[key].files === undefined) {
                    if (typeof data.object[key].value[0] === 'object') {
                        dispatch('validateStep', {
                            object: data.object[key].value,
                            step: data.step,
                            path: `${data.path}.${key}`,
                        })
                    } else {
                        if (data.object[key].step === data.step) {
                            dispatch('changeDataValue', {
                                key: `${data.path}.${key}.error`,
                                value: 'Некорректно заполнено поле',
                            })
                        }
                    }
                } else {
                    if (data.object[key].value === undefined && _.isObject(data.object[key])) {
                        if (Number.isInteger(+key)) {
                            dispatch('validateStep', {
                                object: data.object[key],
                                step: data.step,
                                path: data.path ? `${data.path}.value[${key}]` : key,
                            })
                        } else {
                            dispatch('validateStep', {
                                object: data.object[key],
                                step: data.step,
                                path: data.path ? `${data.path}.${key}` : key,
                            })
                        }
                    } else if (data.object[key].value !== undefined && data.object[key].step === data.step) {
                        if (
                            (data.object[key].files ? !data.object[key].value.length : !data.object[key].value)
                            && data.object[key].required
                        ) {
                            dispatch('changeDataValue', {
                                key: `${data.path}.${key}.error`,
                                value: 'Некорректно заполнено поле',
                            })
                        }
                    }
                }
            }
        }, {})
    },
    changeDataValue({ commit, dispatch }, data) {
        
        commit('CHANGE_DATA', {
            key: data.key.replace(/(.*)(\.)(\d*)(\.)(.*)/, '$1[$3].$5'),
            value: data.value,
        })
        const currentObject = data.key.slice(0, -6)
        if (data.key.split('.')[data.key.split('.').length - 1] === 'value') {
            let rule = _.get(state.data, currentObject + '.rule')
            let value = _.get(state.data, currentObject + '.value')
            let required = _.get(state.data, currentObject + '.required')
            if (
                rule !== undefined &&
                !rule.call(_.get(state.data, currentObject)) &&
                value
            ) {
                commit('CHANGE_DATA', {
                    key: currentObject + '.error',
                    value: _.get(
                        state.data,
                        currentObject + '.ruleMessage'
                    ),
                })
            } else {
                commit('CHANGE_DATA', {
                    key: currentObject + '.error',
                    value: '',
                })
            }
        }
        // if (state.autosaveDataReceived) {
        //     dispatch('autosaveUpdate')
        // }
    },
    setEntityAddress({ dispatch }, data) {
        Object.keys(data).forEach((key) => {
            dispatch('changeDataValue', {
                key: `entity.${key}.value`,
                value: data[key],
            })
        })
    },
    setPersonAddress({ dispatch }, data) {
        Object.keys(data).forEach((key) => {
            dispatch('changeDataValue', {
                key: `person.${key}.value`,
                value: data[key],
            })
        })
    },
    async uploadDocument({ dispatch, commit }, data) {
        const [files, key] = data;
        dispatch('changeDataValue', {
            key: `${key}.value`,
            value: files
        });
    },
    async uploadFile({ dispatch }, file) {
        let { formData } = new UploadData({
            file: file,
            title: file.name,
        })
        let res = await dispatch(
            'file/uploadFileOrg',
            {
                formData,
                type: document,
            },
            { root: true }
        )
        return res
    },
    removeFileFromArray({ dispatch, commit }, data) {
        const [i, key] = data
        let files = _.cloneDeep(_.get(state.data, key).value)
        files.splice(i, 1)
        commit('REMOVE_LOCAL_FILE', {
            key: key,
            index: i,
        })
        dispatch('changeDataValue', {
            key: `${key}.value`,
            value: files,
        })
    },
    addOwnersInfoitem({ commit }) {
        commit('ADD_OWNERS_INFO_ITEM')
    },
    deleteOwnersInfoItem({ commit }, index) {
        commit('DELETE_OWNERS_INFO_ITEM', index)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
