import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    
})

const actions = {
    async dadataGet({ commit }, queryParams) {
        if (!queryParams.queryString) return

        const uri = this.$config.DADATA_LOCAL_ENDPOINT
        const verifyToken = localStorage.getItem('verify_token')

        queryParams.queryType = queryParams.queryType
            ? queryParams.queryType
            : 'party'
        queryParams.count = queryParams.count ? queryParams.count : '6'
        queryParams.parts = queryParams.parts ? queryParams.parts : ''
        queryParams.from_bound = queryParams.from_bound
            ? { value: queryParams.from_bound.value }
            : ''
        queryParams.to_bound = queryParams.to_bound
            ? { value: queryParams.to_bound.value }
            : ''
        queryParams.locations = queryParams.locations
            ? queryParams.locations
            : ''
        queryParams.queryMethod = queryParams.queryMethod
            ? queryParams.queryMethod
            : 'suggest'

        const data = {
            query: queryParams.queryString,
            count: queryParams.count,
            parts: queryParams.parts,
            type: queryParams.type,
            from_bound: queryParams.from_bound,
            to_bound: queryParams.to_bound,
            locations: queryParams.locations,
        }
        if (verifyToken !== null) {
            data.verify_token = verifyToken
        }
        let response = await this.$axios.post(`dadata/${queryParams.queryMethod}/${queryParams.queryType}`, data)

        return response.data.suggestions
    },
    async dadataQuery({ dispatch }, data) {
        return dispatch('dadataGet', {
            queryString: data.query,
            queryType: data.type,
            queryMethod: data.queryMethod || null,
            type: data.getType,
            count: data.count,
            parts: data.parts,
            from_bound: data.from_bound,
            to_bound: data.to_bound,
            locations: data.locations,
        })
    },
}

export { store, actions }
