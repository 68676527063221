import { render, staticRenderFns } from "./default.vue?vue&type=template&id=4189f33d&lang=pug&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=4189f33d&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHeader: require('/app/components/base/Header.vue').default,BaseFooter: require('/app/components/base/Footer.vue').default,RefreshTokenProvider: require('/app/components/RefreshTokenProvider.vue').default})
