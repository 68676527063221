const state = () => ({
    cards: [],
    total: null,
    pages: 0,
    current_page: 0,
    detail: {},
})

const getters = {
};

const actions = {
    async filter({dispatch}, form) {
        let filter = form
        parseInt(form.sumMin, 10)
        parseInt(form.sumMax, 10)
        filter.sumMin = form.sumMin
        filter.sumMax = form.sumMax
        if (form.searchPhrase && form.searchPhrase.length >= 2) {
            filter.searchPhrase = form.searchPhrase.length >= 2 ? form.searchPhrase : ''
        } else {
            filter.searchPhrase = ''
        }
        const res = await dispatch('getShortlist', filter)
        return res
    },
    async getShortlist({ dispatch, commit }, data) {
        const res = await this.$axios({
            method: 'GET',
            url: `/application/shortlist`,
            params: data
        })
        return res
    },
    async getProjects({ dispatch, commit }, form) {
        const res = await dispatch('filter', form)
        commit('setCards', res.data)
        return res
    },
    async moreCards({ commit }, form) {
        const res = await dispatch('getShortlist', form)
        let projects = [...state.cards, ...res.data.items]
        commit('addCards', projects)
        return res
    },
};

const mutations = {
    setCards(state, data) {
        state.cards = null;
        state.cards = data.items;
        state.total = data.meta.total;
        state.pages = Math.ceil(data.meta.total / data.meta.limit);
        state.current_page = data.meta.page;
    },
    addCards(state, newCards) {
        state.cards = newCards;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
