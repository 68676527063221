export const UPLOAD_FILE = (type) => `/intellectual-property/${type}/upload/`;
export const CHECK_DIGIT_SIGN = (pdf, sign) => `pdf/${pdf}/sgn/${sign}/compare`;
export const UPLOAD_FILE_ORG = '/organization/entity/upload/file';
export const UPLOAD_FILE_IP = '/organization/individual/upload/file';
export const UPLOAD_IMAGE = '/organization/entity/upload/image';
export const UPLOAD_PASSPORT = '/register/upload-scan-file';
export const UPLOAD_CONTRACT_SIGNATURE = '/compare/upload';
export const UPLOAD_SIGNED_FILE = '/file/upload/signed';
export const UPLOAD_FILE_TICKET = '/ticket/upload-file';
export const FILE_VIEW = (apiUrl, id) => `${apiUrl}/file/inline/${id}`;
