
import { mapState, mapGetters, mapActions } from 'vuex';
import BaseProfileMenu from './ProfileMenu';
import LayoutContainer from '@/components/layout/Container';
import HeaderBalance from '@/components/common/HeaderBalance';

export default {
    name: 'BaseHeader',
    components: {
        HeaderBalance,
        LayoutContainer,
        BaseProfileMenu
    },
    props: {
        theme: {
            type: String,
            default: 'light'
        }
    },
    data() {
        return {
            mobileMenuOpen: false,
            accountSubmenuOpen: false
        }
    },
    watch: {
        $route() {
            this.handleCloseMobileMenu()
        }
    },
    computed: {
        currentLogo() {
            if (this.theme === 'light' || this.theme === 'black' && this.mobileMenuOpen) return 'logo-white';
            else return 'logo-black';
        },
        // balanceIsVisible() {
        //     return this.$route.name !== 'borrower' && this.$route.name !== 'investor' && this.$route.name !== 'index'
        // },
        menuList() {
            return {
                dashboard: { title: 'Дэшборд', route: 'profile-dashboard', bordered: true },
                // vitrina: { title: 'Витрина', route: 'vitrina', bordered: true },
                index: { title: 'Бизнесу', route: 'index', bordered: true },
                investorLanding: { title: 'Инвесторам', route: 'investor', bordered: true },
                // aboutPage: { title: 'Как это работает?', route: 'about', bordered: true },
                offers: { title: 'Мои инвестиции', route: 'profile-my-offers', bordered: true },
                favourites: { title: 'Избранное', route: 'profile-favourites', bordered: true, after: this.favoritesCount || ''},
                myProfiles: { title: 'Мои профили', route: 'profile-my-profiles', bordered: true },
                profileSupport: { title: 'Служба поддержки', route: 'profile-support', bordered: true },
                pledge: { title: 'Мои залоги', route: 'profile-oises', bordered: true },
                applications: { title: 'Мои заявки', route: 'profile-my-orders', bordered: true },
                changeProfile: { title: 'Сменить профиль', id: 'changeProfile' },
                login: { title: 'Войти', class: 'base-header__menu-item--login', link: this.$config.AUTH, referrerpolicy: 'no-referrer-when-downgrade' },
                logout: { title: 'Выйти', fn: this.handleLogout, id: 'logout' },
                profileDocuments: { title: 'Документы', route: 'profile-documents', bordered: true },
                blog: { title: 'Блог', route: 'blog', bordered: true },
                profile: { title: 'Профиль', fn: this.handleProfileRoute },
            };
        },
        isRefused() {
            return this.currentAccount && this.currentAccount.status === 'refused';
        },
        isArchived() {
            return this.currentAccount && this.currentAccount.status === 'archived';
        },
        isRejected() {
            return this.currentAccount && this.currentAccount.status === 'rejected';
        },
        isIdentified() {
            return this.currentAccount && this.currentAccount.status === 'identified';
        },
        isSetStatus() {
            return this.currentAccount && this.currentAccount.status === 'set_status';
        },
        isChecked() {
            return this.currentAccount && this.currentAccount.status === 'checked';
        },
        isVerified() {
            return this.currentAccount && this.currentAccount.status === 'verified';
        },
        shortAccountName() {
            if (this.currentAccount.type === 'individual') {
                const fullName = this.currentAccount.name.split('ИП ')[1];
                const nameComponents = fullName.split(' ');
                const lastName = !!nameComponents[0] ? nameComponents[0] : ''
                const name = !!nameComponents[1] ? (nameComponents[1].charAt(0) + '.') : ''
                const patronymic = !!nameComponents[2] ? (nameComponents[2].charAt(0) + '.') : ''
                const shortName = `ИП ${lastName} ${name}${patronymic}`
                return shortName
            } else if (this.currentAccount.type === 'personal') {
                const nameComponents = this.currentAccount.name.split(' ');
                const lastName = !!nameComponents[0] ? nameComponents[0] : ''
                const name = !!nameComponents[1] ? (nameComponents[1].charAt(0) + '.') : ''
                const patronymic = !!nameComponents[2] ? (nameComponents[2].charAt(0) + '.') : ''
                const shortName = `${lastName} ${name}${patronymic}`
                return shortName
            } else {
                return this.currentAccount.name
            }
        },
        desktopMenu() {
            if (this.isAuthorized) {
                if (this.currentAccount) {
                    if (!this.isRefused && !this.isArchived && !this.isRejected && !this.isSetStatus && !this.isChecked) {
                        let subMenu = [
                            this.menuList.profile,
                            this.menuList.profileSupport,
                            this.menuList.logout
                        ]
                        if (this.profiles.length > 1) {
                            subMenu.unshift(this.menuList.changeProfile)
                        }
                        if (this.role === 'investor') {
                            return [
                                this.menuList.offers,
                                // this.menuList.vitrina,
                                this.menuList.blog,
                                {
                                    title: this.shortAccountName,
                                    class: 'base-header__menu-item--disable-hover',
                                    items: subMenu,
                                    // Вместо стандартной css реализации можем использовать компонент
                                    component: 'BaseProfileMenu'
                                }
                            ];
                        } else if (this.role === 'borrower') {
                            let menu = [
                                this.menuList.applications,
                                // this.menuList.vitrina,
                                this.menuList.blog,
                                {
                                    title: this.shortAccountName,
                                    class: 'base-header__menu-item--disable-hover',
                                    items: subMenu,
                                    // Вместо стандартной css реализации можем использовать компонент
                                    component: 'BaseProfileMenu'
                                }
                            ];
                            return menu;
                        }
                    } else {
                        return [
                            this.menuList.dashboard,
                            this.menuList.index,
                            this.menuList.investorLanding,
                            // this.menuList.vitrina,
                            this.menuList.blog,
                            {
                                title: this.credentials?.login || 'Профиль',
                                class: 'base-header__menu-item--disable-hover',
                                items: [
                                    // this.menuList.myProfiles,
                                    this.menuList.profileSupport,
                                    this.menuList.logout
                                ],
                                // Вместо стандартной css реализации можем использовать компонент
                                component: 'BaseProfileMenu'
                            }
                        ]
                    }
                } else {
                    let menuItems = [
                        this.menuList.dashboard,
                        this.menuList.index,
                        this.menuList.investorLanding,
                        // this.menuList.vitrina,
                        this.menuList.blog,
                    ]
                    if (this.role === 'investor') {
                        menuItems.push(this.menuList.favourites)
                    }
                    return [
                        ...menuItems,
                        // this.menuList.aboutPage,
                        {
                            title: this.credentials?.login || 'Профиль',
                            class: 'base-header__menu-item--disable-hover',
                            items: [
                                // this.menuList.myProfiles,
                                this.menuList.profileSupport,
                                this.menuList.logout
                            ],
                            // Вместо стандартной css реализации можем использовать компонент
                            component: 'BaseProfileMenu'
                        }
                    ]
                }
            }

            return [
                this.menuList.index,
                this.menuList.investorLanding,
                // this.menuList.vitrina,
                // this.menuList.aboutPage,
                this.menuList.blog,
                this.menuList.login
            ]
        },
        ...mapState('profile', ['currentAccount', 'isAuthorized', 'credentials', 'profiles']),
        ...mapState('favorite', { favoritesCount: 'count' }),
        ...mapGetters('profile', [
            'role'
        ])
    },
    created() {
        if (this.currentAccount && this.role === 'investor') {
            this.getFavouritesCount();
        }
    },
    methods: {
        handleProfileRoute() {
            switch(this.currentAccount.type) {
                case 'individual':
                    this.$router.push({
                        name: 'profile-individual-profile-id',
                        params: {
                            id: this.currentAccount.account_id
                        }
                    })
                    break;
                case 'entity':
                    this.$router.push({
                        name: 'profile-entity-profile-id',
                        params: {
                            id: this.currentAccount.account_id
                        }
                    })
                    break;
                case 'personal':
                    this.$router.push({
                        name: 'profile-personal-profile-id',
                        params: {
                            id: this.currentAccount.account_id
                        }
                    })
                    break;
            }
        },
        clickItemRoute(route) {
            if (this.$route.name === route) {
                this.handleCloseMobileMenu()
            }
        },
        changeProfile(profile) {
            if (!profile.is_active && (profile.status === 'verified' || profile.status === 'identified')) {
                this.changeCurrentProfile(profile.account_id)
            }
        },
        handleOpenMobileMenu() {
            this.mobileMenuOpen = true;
            if (document) {
                document.querySelector('html').classList.add('openedMobileMenu')
            }
        },
        handleCloseMobileMenu() {
            this.mobileMenuOpen = false;
            if (document) {
                document.querySelector('html').classList.remove('openedMobileMenu')
            }
        },
        handleLogout() {
            this.$router.push({
                name: 'logout'
            })
        },
        handleFnMenuItem(item) {
            if (item.fn) return item.fn();
        },
        ...mapActions('favorite', ['getFavouritesCount']),
        ...mapActions('profile', ['changeCurrentProfile']),
    }
}
