import { APPLICATION_VIEW } from '../const/api/application';

export default async function (context) {
    if (process.server) {
        const id = context.req.url.split('/')[2];
        await context.$axios(APPLICATION_VIEW(id))
            .then(({ data }) => {
                if (data?.item?.slug) {
                    context.redirect(301, { name: 'project-slug', params: { slug: data.item.slug } });
                } else {
                    context.redirect(301, '/');
                }
            })
            .catch(({ response }) => {
                context.redirect(301, '/');
            });
    }
}
