
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            profilesDropdownOpened: false,
        }
    },
    computed: {
        ...mapState('profile', [
            'currentAccount',
            'isAuthorized',
            'credentials',
            'profiles'
        ]),
        ...mapGetters('profile', [
            'role'
        ]),
        sortedProfiles() {
            if (this.profiles) {
                return _.cloneDeep(this.profiles).sort((a, b) => a.is_active === b.is_active ? 0 : a.is_active ? 1 : -1);
            }
            return []
        },
        // balanceIsVisible() {
        //     return this.$route.name !== 'borrower' && this.$route.name !== 'investor' && this.$route.name !== 'index'
        // },
        shortAccountName() {
            if (this.currentAccount.type === 'individual') {
                const fullName = this.currentAccount.name.split('ИП ')[1];
                const nameComponents = fullName.split(' ');
                const lastName = !!nameComponents[0] ? nameComponents[0] : ''
                const name = !!nameComponents[1] ? (nameComponents[1].charAt(0) + '.') : ''
                const patronymic = !!nameComponents[2] ? (nameComponents[2].charAt(0) + '.') : ''
                const shortName = `ИП ${lastName} ${name}${patronymic}`
                return shortName
            } else if (this.currentAccount.type === 'personal') {
                const nameComponents = this.currentAccount.name.split(' ');
                const lastName = !!nameComponents[0] ? nameComponents[0] : ''
                const name = !!nameComponents[1] ? (nameComponents[1].charAt(0) + '.') : ''
                const patronymic = !!nameComponents[2] ? (nameComponents[2].charAt(0) + '.') : ''
                const shortName = `${lastName} ${name}${patronymic}`
                return shortName
            }
            return this.currentAccount.name
        },
    },
    methods: {
        ...mapActions('profile', [
            'changeCurrentProfile'
        ]),
        toggleProfilesDropdown() {
            if (this.profiles.length > 1) {
                this.profilesDropdownOpened = !this.profilesDropdownOpened
            }
        },
        goToBalance() {
            this.$router.push({ name: 'profile-balance' });
            this.$emit('close')
        },
        addBalance() {
            if (this.currentAccount) {
                this.$router.push({ name: 'profile-balance-replenishment' });
                this.$emit('close')
            }
        },
        goToProfilePage() {
            if (this.currentAccount) {
                switch(this.currentAccount.type) {
                        case 'individual':
                            this.$router.push({
                                name: 'profile-individual-profile-id',
                                params: {
                                    id: this.currentAccount.account_id
                                }
                            })
                            break;
                        case 'entity':
                            this.$router.push({
                                name: 'profile-entity-profile-id',
                                params: {
                                    id: this.currentAccount.account_id
                                }
                            })
                            break;
                        case 'personal':
                            this.$router.push({
                                name: 'profile-personal-profile-id',
                                params: {
                                    id: this.currentAccount.account_id
                                }
                            })
                            break;
                    }
            }
        },
        changeProfile(profile) {
            if (!profile.is_active && (profile.status === 'verified' || profile.status === 'identified')) {
                this.$emit('close')
                this.changeCurrentProfile(profile.account_id)
            }
        },
        emptyProfileName(profile) {
            switch (profile.type) {
                case 'entity':
                    return 'Профиль ЮЛ'
                case 'personal':
                    return 'Профиль ФЛ'
                default:
                    return 'Профиль ИП'
            }
        },
    }
}
