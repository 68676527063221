import { getMessage } from '~/const/vuelidateMessages';

export const mixFieldValidate = {
    computed: {
        vuelidateErrorMessage() {
            if (this.validate && this.validate.$error) {
                const validations =
                    this.validate.$params && Object.keys(this.validate.$params)
                const typeError = validations.find(
                    (property) => this.validate[property] === false
                )
                return this?.validateErrorMessages?.[typeError] ? this.validateErrorMessages[typeError] : getMessage(typeError, this.validate.$params[typeError]);
            }
            return ''
        }
    }
}

export const mixFormValidate = {
    methods: {
        scrollFirstError(data, additionalKeyForId) {
            const validations = Object.keys(data.$params);
            const typeError = validations.find(property => data[property].$error);
            if (data[typeError].$each) {
                const { $iter } = data[typeError].$each;
                const iterError = Object.keys($iter).find(key => $iter[key].$error);
                if ($iter[iterError].$params && Object.keys($iter[iterError].$params).length) {
                    return this.scrollFirstError($iter[iterError], iterError);
                }
                const element = document.querySelector(`#${typeError}-${iterError}`);
                if (element) {
                    return element.scrollIntoView({ block: "center", behavior: "smooth" });
                } else {
                    return console.error('Не найден элемент', id);
                }
            }
            if (data[typeError].$params && typeof data[typeError].$model === 'object' && data[typeError].$model !== null) {
                const modelKeys = Object.keys(data[typeError].$model);
                const result = Object.keys(data[typeError].$params).every(param => modelKeys.includes(param));
                if (result) return this.scrollFirstError(data[typeError], typeError);
            }
            const id = additionalKeyForId ? `#${typeError}-${additionalKeyForId}` : `#${typeError}`;
            const element = document.querySelector(id);
            if (element) {
                return element.scrollIntoView({ block: "center", behavior: "smooth" });
            } else {
                console.error('Не найден элемент', id);
            }
        },
        touchForm(form, additionalKeyForId, scroll = true) {
            form.$touch();
            return new Promise((resolve, reject) => {
                if (form.$invalid) {
                    reject({ form, scroll: this.scrollFirstError.bind(this, form, additionalKeyForId) });
                    return scroll ? this.scrollFirstError(form, additionalKeyForId) : null;
                }
                return resolve(form);
            });
        }
    }
};
