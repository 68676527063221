import Vue from 'vue'
import {
    UPLOAD_FILE,
    UPLOAD_IMAGE,
    UPLOAD_FILE_ORG,
    UPLOAD_PASSPORT,
    CHECK_DIGIT_SIGN,
    UPLOAD_SIGNED_FILE,
    UPLOAD_FILE_TICKET,
    UPLOAD_CONTRACT_SIGNATURE,
} from '~/const/api/file'

const state = () => ({
    files: {
        documents: []
    },
    imagaesSourses: {
        imageFrontPassportSrc: '',
        imageBackPassportSrc: '',
        imagePdfPassportSrc: '',
        imagePdfPassportBackSrc: '',
        imageTiffPassportSrc: '',
        imageTiffPassportBackSrc: ''
    },
    PassportFormat: {
        front: '',
        back: ''
    }
})

const getters = {
    intelectualPropsId: state => state.files,
    documentsId: state => {
        if (state.files.documents) {
            return state.files.documents.map(i => i.id)
        }
    },
    getPassportImageSrc: state => {
        return state.imagaesSourses
    },
    getPassportFormat: state => {
        return state.PassportFormat
    }
}

const actions = {
    clearDocs({commit}) {
        commit('cleanDocs')
    },
    async checkDigitSign({commit}, data) {
        const response = await this.$axios.post(CHECK_DIGIT_SIGN, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: loadProgress,
        })
        return response;
    },
    async uploadFile({commit}, {data, type, loadProgress}) {
        const response = await this.$axios.post(UPLOAD_FILE(type), data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: loadProgress,
        })
        if (response.type !== 'document') {
            commit('setFile', response)
        } else {
            commit('setDocuments', response)
        }
        return response
    },
    async uploadSignedFile({commit}, {data, type, loadProgress}) {
        const response = await this.$axios.post(UPLOAD_SIGNED_FILE, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: loadProgress,
        })
        return response
    },
    async uploadFileOrg({commit}, {data, type, loadProgress}) {
        const response = await this.$axios.post(UPLOAD_FILE_ORG, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: loadProgress,
        })
        if (response.type !== 'document') {
            commit('setFile', response)
        } else {
            commit('setDocuments', response)
        }
        return response;
    },
    async uploadFileTicket({commit}, {data, type, loadProgress}) {
        const response = await this.$axios.post(UPLOAD_FILE_TICKET, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: loadProgress,
        })
        if (response.type !== 'document') {
            commit('setFile', response)
        } else {
            commit('setDocuments', response)
        }
        return response;
    },
    async uploadImage({commit}, {data, type, loadProgress}) {
        const response = await this.$axios.post(UPLOAD_IMAGE, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: loadProgress,
        })
        if (response.type !== 'document') {
            commit('setFile', response)
        } else {
            commit('setDocuments', response)
        }
        return response;
    },
    async uploadFileIp({commit}, {data, type, loadProgress}) {
        const response = await this.$axios.post(UPLOAD_FILE_ORG, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: loadProgress,
        })
        if (response.type !== 'document') {
            commit('setFile', response)
        } else {
            commit('setDocuments', response)
        }
        return response;
    },

    async uploadConractSignature({commit}, data) {
        const response = await this.$axios.post(UPLOAD_CONTRACT_SIGNATURE, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: loadProgress,
        })
        return response;
    },

    async uploadPassport({commit}, data) {
        const response = await this.$axios.post(UPLOAD_PASSPORT, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: loadProgress,
        })
        return response;
    },
}

const mutations = {
    setFile(state, file) {
        const fileId = state.files[file.id]
        if (!fileId) {
            Vue.set(state.files, file.type, file)
        }
    },
    setDocuments(state, document) {
        state.files.documents.push(document)
    },
    cleanDocs(state) {
        state.files.documents = []
    },
    setImageFrontPassportSrc(state, src) {
        state.imagaesSourses.imageFrontPassportSrc = src;
    },
    setImageBackPassportSrc(state, src) {
        state.imagaesSourses.imageBackPassportSrc = src;
    },
    setImagePdfPassportSrc(state, src) {
        state.imagaesSourses.imagePdfPassportSrc = src;
    },
    setImagePdfPassportBackSrc(state, src) {
        state.imagaesSourses.imagePdfPassportBackSrc = src;
    },
    setPassportFormat(state, format) {
        state.PassportFormat.front = format;
    },
    setPassportBackFormat(state, format) {
        state.PassportFormat.back = format;
    },
    setImageTiffPassportSrc(state, src) {
        state.imagaesSourses.imageTiffPassportSrc = src;
    },
    setImageTiffPassportBackSrc(state, src) {
        state.imagaesSourses.imageTiffPassportBackSrc = src;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
