const state = () => ({
    balance: 0,
    form: {
        application_id: null,
        sum: null,
        percent: null,
    },
    pdfOfferId: '',
})

const getters = {
    getPdfOfferId: (state) => {
        return state.pdfOfferId
    },
    reInvest: (state) => {
        if (state.form.application_id) {
            return state.form
        }
    },
}

const actions = {
    async invest({ commit }, form) {
        commit('setInvestData', form)
        const res = await this.$axios.post('/invest', {
            application_id: form.application_id,
            sum: form.sum,
        }, {
            withCredentials: true,
        })
        return res
    },
    confirmInvest({ commit }, form) {
        return this.$axios.post('invest/verify', {
            application_id: form.application_id,
            code: form.code,
        });
    },
    async cancelInvest({commit}, cardId) {
        const res = await this.$axios.post('invest/withdraw-offer/send', {
            id: cardId,
        })
        return res
    },
    async cancelInvestConfirm({ commit }, data) {
        const res = await this.$axios.post('invest/withdraw-offer/verify', {
            id: data.id,
            code: data.code,
        })
        return res
    },
}

const mutations = {
    setInvestData(state, form) {
        state.form.application_id = form.application_id
        state.form.sum = form.sum
        state.form.percent = form.percent
    },
    setPdfId({ commit }, id) {
        state.pdfOfferId = id
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
