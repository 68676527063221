
import VButton from '~/components/ui/VButton'
import {mapActions} from 'vuex'

export default {
    name: 'RoundAlert',
    components: {
        VButton
    },
    data() {
        return {
            application: null,
            callback: null
        }
    },
    methods: {
        ...mapActions('service', [
            'cancelInvest'
        ]),
        beforeOpen(event) {
            this.application = event.params.application
            this.callback = event.params.callback || null
        },
        beforeClose(data) {
            this.callback = null;
        },
        closeModal() {
            this.$modal.hide('project-invest-cancel')
        },
        cancelDeal() {
            this.loading = true
            this.cancelInvest(
                this.application.id
            ).then(() => {
                this.loading = false;
                if (this.callback) this.callback();
                this.$modal.hide('project-invest-cancel');
            });
        },
    },
}
