
import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapState('profile', [
            'currentAccount',
            'isAuthorized',
            'credentials',
            'profiles'
        ]),
        ...mapGetters('profile', [
            'role'
        ]),
        isRefused() {
            return this.currentAccount && this.currentAccount.status === 'refused';
        },
        isArchived() {
            return this.currentAccount && this.currentAccount.status === 'archived';
        },
        isRejected() {
            return this.currentAccount && this.currentAccount.status === 'rejected';
        },
        isIdentified() {
            return this.currentAccount && this.currentAccount.status === 'identified';
        },
        isSetStatus() {
            return this.currentAccount && this.currentAccount.status === 'set_status';
        },
        isChecked() {
            return this.currentAccount && this.currentAccount.status === 'checked';
        },
        isVerified() {
            return this.currentAccount && this.currentAccount.status === 'verified';
        },
        menuList() {
            return {
                dashboard: { label: 'Дэшборд', to: {name: 'profile-dashboard'} },
                // vitrina: { label: 'Витрина', to: {name: 'vitrina'} },
                index: { label: 'Бизнесу', to: {name: 'index'} },
                investorLanding: { label: 'Инвесторам', to: {name: 'investor'} },
                // aboutPage: { label: 'Как это работает?', to: {name: 'about'} },
                offers: { label: 'Мои инвестиции', to: {name: 'profile-my-offers'} },
                myProfiles: { label: 'Мои профили', to: {name: 'profile-my-profiles'} },
                profileSupport: { label: 'Служба поддержки', to: {name: 'profile-support'} },
                pledge: { label: 'Мои залоги', to: {name: 'profile-oises'} },
                applications: { label: 'Мои заявки', to: {name: 'profile-my-orders'} },
                changeProfile: { label: 'Сменить профиль' },
                logout: { label: 'Выйти', fn: this.handleLogout },
                profileDocuments: { label: 'Документы', to: {name: 'profile-documents'} },
                blog: { label: 'Блог', to: {name: 'blog'} }
            };
        },
        generalNavigationItems() {
            if (this.isAuthorized) {
                if (!this.isRefused && !this.isArchived && !this.isRejected && !this.isSetStatus && !this.isChecked) {
                    if (this.role === 'investor') {
                        return [
                            this.menuList.offers,
                            // this.menuList.vitrina,
                            this.menuList.blog,
                        ]
                    } else if (this.role === 'borrower') {
                        return [
                            this.menuList.applications,
                            // this.menuList.vitrina,
                            this.menuList.blog
                        ]
                    }
                } else {
                    return [
                        this.menuList.index,
                        this.menuList.investorLanding,
                        // this.menuList.vitrina,
                        this.menuList.myProfiles,
                        this.menuList.profileSupport,
                        this.menuList.blog
                    ]
                }
            } else {
                return [
                    this.menuList.index,
                    this.menuList.investorLanding,
                    // this.menuList.vitrina,
                    this.menuList.blog,
                ]
            }
        }
    },
    methods: {
        handleClick(link) {
            if (link.to) {
                this.$router.push(link.to);
            } else if (link.fn) {
                link.fn()
            }
            this.$emit('close')
            return false
        }
    }
}
