

export default {
    data() {
        return {
            title: '',
            h3: '',
            description: '',
            content: null,
            maxWidth: 600,
            cross: true,
            clickOutside: true,
        }
    },
    methods: {
        hideDefaultModal() {
            if (this.clickOutside) this.$modal.hide('default-modal')
        },
        disableBodyScroll() {
            document.body.style.overflow = 'hidden'
            // document.body.style.paddingRight = '15px'
        },
        enableBodyScroll() {
            document.body.style = ''
        },
        escEvent(event) {
            if (event.code === 'Escape') {
                this.hideDefaultModal()
            }
        },
        beforeOpen(data) {
            this.content = data.params.content
            this.title = data.params.title
            this.h3 = data.params.h3
            this.description = data.params.description
            this.maxWidth = data.params.maxWidth
            this.cross = data.params?.cross ?? this.cross
            this.clickOutside = data.params?.clickOutside ?? this.clickOutside
            this.disableBodyScroll()
            if (this.clickOutside) {
                window.addEventListener("keyup", this.escEvent);
            }
        },
        beforeClose() {
            this.content = null
            this.title = ''
            this.h3 = ''
            this.description = ''
            this.maxWidth = 600
            this.cross = true
            this.clickOutside = true
            this.enableBodyScroll()
            if (this.clickOutside) {
                window.addEventListener("keyup", this.escEvent);
            }
        },
    }
}
