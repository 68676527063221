
import { mapGetters, mapState } from 'vuex'

export default {
    props: {
        balance: {
            type: String,
            default: ''
        },
        theme: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            ready: false,
        }
    },
    computed: {
        ...mapGetters('profile', [
            'role'
        ]),
        ...mapState('profile', [
            'currentAccount'
        ]),
    },
    mounted() {
        this.ready = true
    },
    methods: {
        goToBalance() {
            this.$router.push({ name: 'profile-balance' });
        },
        addBalance() {
            this.$router.push({ name: 'profile-balance-replenishment' });
        },
    }
}
