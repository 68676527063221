const state = () => ({
})

const getters = {
}

const actions = {
    async getShedule({commit}, form) {
        const res = this.$axios.get('/payment-schedule', {
            params: {
                applicationId: form.applicationId,
                sum: form.sum
            },
            responseType: 'arraybuffer',
        })
        return res
    },
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
