export const rules = {
    common: {
        message: (params) => 'Заполните корректно поле'
    },
    mask: {
        message: (params) => 'Заполните корректно поле'
    },
    required: {
        message: (params) => 'Поле обязательно для заполнения'
    },
    correctYear: {
        message: (params) => `Год должен быть в диапазоне от 1900 до ${(new Date()).getFullYear()}`
    },
    investProductMinSum: {
        message: (params) => `Сумма должна быть не менее ${params.min} ₽`
    },
    investProductMaxSum: {
        message: (params) => `Сумма должна не превышать ${params.max} ₽`
    },
    interestProductRateFrom: {
        message: (params) => `Процент должен быть не ниже ${params.min}`
    },
    interestProductRateTo: {
        message: (params) => `Процент должен быть не более ${params.max}`
    },
    noRemainder1000: {
        message: (params) => 'Сумма должна быть кратна 1000'
    },
    email: {
        message: (params) => 'Введите корректный email'
    },
    password: {
        message: (params) => 'Пароль должен содержать не менее 8 латинских букв и цифр (как минимум 1 цифра, 1 строчная и 1 заглавная буквы)'
    },
    repeatPassword: {
        message: (params) => 'Пароли должны совпадать'
    }
}

export function getMessage(rule, params) {
    return rules?.[rule]?.message(params) || 'Неизвестная ошибка';
}
