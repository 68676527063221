export default function (context) {
    context.$axios.onResponseError(error => {
        if (process.client && error?.response?.status && error.response.status === 401) {
            const route = context.route.name
            context.store.dispatch('profile/refreshToken')
                .then(() => {
                    if (route === 'auth') {
                        context.$router.push({
                            name: 'logout'
                        })
                    } else {
                        window.location.reload()
                    }
                })
                .catch(() => {
                    context.$router.push({
                        name: 'logout'
                    })
                });
        }
    })
}
