
import VDropdownSimple from '~/components/ui/VDropdown/Simple'
import { TheMask } from 'vue-the-mask'
const CURRENCY_FORMAT = 'currency';
const NUMBER_FORMAT = 'number';
const PERCENT_FORMAT = 'percent';

export default {
    name: 'VInput',
    components: {
        TheMask,
        VDropdownSimple
    },
    inheritAttrs: false,
    props: {
        mask: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number, Boolean],
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        error: {
            type: [String, Array],
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            default: ''
        },
        masked: {
            type: Boolean,
            default: false
        },
        postfix: {
            type: String,
            default: ''
        },
        maxlength: {
            type: String,
            default: ''
        },
        format: {
            type: String,
            default: '',
            validator(value) {
                return value ? [CURRENCY_FORMAT, NUMBER_FORMAT, PERCENT_FORMAT].includes(value) : true;
            }
        },
        fieldTitle: {
            type: String,
            default: 'name'
        },
        fieldValue: {
            type: String,
            default: 'value'
        },
        options: {
            type: Array,
            default: () => []
        },
        returnObject: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            focused: false,
            localValuePostfix: ''
        }
    },
    computed: {
        localValue: {
            get() {
                let value = this.value;
                if (this.format) {
                    if (this.format === CURRENCY_FORMAT || this.format === NUMBER_FORMAT) {
                        let cleanValue
                        if (this.format === NUMBER_FORMAT) {
                            cleanValue = Number(this.transformToNumber(this.value));
                        } else {
                            cleanValue = this.transformToNumber(this.value)
                        }
                        if (value && !Number.isNaN(cleanValue)) {
                            value = this.format === CURRENCY_FORMAT ? (+cleanValue).toLocaleString('ru-RU') : cleanValue;
                        }
                    }
                }
                if (this.postfix) {
                    if (!this.focused && this.value) {
                        this.localValuePostfix = `${value} ${this.postfix}`;
                        return this.localValuePostfix;
                    }
                }
                return value;
            },
            set(value) {
                if (!this.readonly && value !== this.localValue) {
                    if (this.format === CURRENCY_FORMAT || this.format === NUMBER_FORMAT) {
                        if (/^[1-9]{0,1}[0-9\s]*[\.\,]?[0-9\s]{0,2}$/.test(value)) {
                            this.updateValue(this.transformToNumber(value));
                        }
                    } else if (this.format === PERCENT_FORMAT) {
                        if (/(^100(,0{1,2})?$)|(^([1-9]([0-9])?|0)(,[0-9]{0,2})?$)/.test(value) || value === '') {
                            this.updateValue(value);
                        } else {
                            this.$refs.input.value = this.localValue;
                        }
                    } else {
                        this.updateValue(value);
                    }
                }
            }
        },
        showDropdown() {
            return !!(this.focused && this.localValue && this.options.length);
        }
    },
    methods: {
        updateValue(value) {
            if (!this.disabled && !this.readonly) this.$emit('input', value);
        },
        handleFocus() {
            this.$emit('focus');
            this.focused = true;
        },
        handleBlur() {
            this.$emit('blur');
            this.focused = false;
        },
        handleSelect(data) {
            const fieldValue = data[this.fieldValue];
            this.$emit('input', fieldValue);
            this.$emit('select', fieldValue, data);
        },
        transformToNumber(value) {
            return value.toString().replace(/\s/g, '').replace(/,/g, '.');
        }
    },
}
