
import VButton from '~/components/ui/VButton'
import { mapGetters, mapState } from 'vuex'

export default {
    components: {
        VButton,
    },
    data() {
        return {
            code1: '',
            code2: '',
            code3: '',
            code4: '',
            documents: '',
            error: '',
            title: '',
            description: '',
            phone: '',
            footerText: '',
            sendCode: '',
            getCode: '',
            name: '',
            time: null,
            timer: null,
            timerLimit: 60,
            ready: false,
            loading: false,
            maxWidth: 600,
        }
    },
    computed: {
        ...mapState('profile', [
            'currentAccount',
            'credentials'
        ]),
        ...mapGetters('profile', [
            'role'
        ]),
        phoneNumber() {
            return this.phone || this.credentials?.phone_number;
        },
        codeIsDone() {
            return this.code1 && this.code2 && this.code3 && this.code4
        }
    },
    watch: {
        code1() {
            if (this.code1.length) {
                this.$refs.code2.focus()
                if (this.codeIsDone) {
                    this.submit()
                }
            }
        },
        code2() {
            if (this.code2.length) {
                this.$refs.code3.focus()
                if (this.codeIsDone) {
                    this.submit()
                }
            } else {
                this.$refs.code1.focus()
            }
        },
        code3() {
            if (this.code3.length) {
                this.$refs.code4.focus()
                if (this.codeIsDone) {
                    this.submit()
                }
            } else {
                this.$refs.code2.focus()
            }
        },
        code4() {
            if (this.code4.length) {
                if (this.codeIsDone) {
                    this.submit()
                }
            } else {
                this.$refs.code3.focus()
            }
        },
    },
    methods: {
        hide() {
            this.$modal.hide('sms-confirm')
        },
        tick() {
            clearInterval(this.timer)
            this.timer = setInterval(() => {
                this.tickTimer()
            }, 1000)
        },
        tickTimer() {
            let seconds = new Date(
                +new Date() - +localStorage.getItem(this.name)
            ).getSeconds()

            if (seconds >= this.timerLimit - 1) {
                this.time = 0
                localStorage.removeItem(this.name)
                clearInterval(this.timer)
            } else {
                this.time = this.timerLimit - seconds
            }
        },
        beforeOpen(data) {
            this.code1 = ''
            this.code2 = ''
            this.code3 = ''
            this.code4 = ''
            this.title = data.params.title || ''
            this.description = data.params.description || ''
            this.documents = data.params.documents || []
            this.phone = data.params.phone || ''
            this.sendCode = data.params.sendCode
            this.name = data.params.name || ''
            this.compilePhoneError = data.params.compilePhoneError || ''
            if (data.params.getCode) {
                this.getCode = data.params.getCode
                if (!data.params.disableAutoGet) {
                    this.getSmsCode()
                }
            }
            if (data.params.disableAutoGet) {
                localStorage.setItem(this.name, +new Date())
                this.tick()
            }
            setTimeout(() => {
                if (this.$refs.code1) {
                    this.$refs.code1.focus()
                }
            }, 300)
        },
        getSmsCode() {
            this.error = ''
            this.getCode()
                .then(() => {
                    localStorage.setItem(this.name, +new Date())
                    this.tick()
                })
                .catch((err) => {
                    let {data} = err
                    if (localStorage.getItem(this.name)) {
                        this.tick()
                    }
                    if (data.error === 'action_sms_code_send_timeout') {
                        this.error = 'Смс-код уже выслан и действителен в течении 10 минут'
                    } else {
                        this.error = data.errors && data.errors.phone_number ? data.errors.phone_number : (data.error_message || 'Возникла непредвиденная ошибка')
                    }
                    if (this.compilePhoneError) {
                        this.compilePhoneError(data)
                    }
                })
        },
        beforeClose() {
            this.code1 = ''
            this.code2 = ''
            this.code3 = ''
            this.code4 = ''
            this.error = ''
            this.title = this.title || ''
            this.description = this.description || ''
            this.documents = this.documents || []
            this.phone = this.phone || ''
            this.sendCode = this.sendCode
            this.name = this.name || ''
            this.compilePhoneError = this.compilePhoneError || ''
            this.time = null
            clearInterval(this.timer)
        },
        submit() {
            if (!this.disabled && !this.loading) {
                this.loading = true;
                this.sendCode(`${this.code1}${this.code2}${this.code3}${this.code4}`)
                    .then(() => {
                        this.$modal.hide('sms-confirm')
                    })
                    .catch((err) => {
                        this.code1 = ''
                        this.code2 = ''
                        this.code3 = ''
                        this.code4 = ''
                        if (err.response.data.errors) {
                            this.error = err.response.data.errors.code
                        } else {
                            this.error =
                                err.response.data.error_message ||
                                'Возникла непредвиденная ошибка'
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            }
        },
        resend() {
            this.code1 = ''
            this.code2 = ''
            this.code3 = ''
            this.code4 = ''
            this.error = ''
            this.getSmsCode()
        },
    },
}
